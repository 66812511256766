import { createAction, handleActions } from 'redux-actions';
import { combineReducers } from 'redux';

const FOOTER_ENTRIES_SET = 'FOOTER_ENTRIES_SET';

export const setFooterEntries = createAction(FOOTER_ENTRIES_SET);

const dataReducer = handleActions(
  {
    [FOOTER_ENTRIES_SET]: (state, action) => action.payload,
  },
  []
);

export const footerReducer = combineReducers({ data: dataReducer });
