import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import trans from '../components/trans';
// import UsedLicenses from '../components/UsedLicenses';
import BackButton from '../components/BackButton';
import { Helmet } from 'react-helmet';

const Container = styled.div`
  background-color: #f4f5f7;
`;

const Block = styled.div`
  max-width: 1280px;
  margin: 0 auto;
  padding: 20px 0px;
  @media (max-width: 1280px) {
    padding: 20px 15px;
  }
`;

export default function Impressum() {
  const navigate = useNavigate()
  const impressumhtml = useSelector(state => state.footer && state.footer.data && state.footer.data.f381t_Web_Impressum)
  const impressumtitel = useSelector(state => state.footer && state.footer.data && state.footer.data.f380t_Web_Impressum_Titel)
  if (!impressumhtml) {
    return null;
  }
  return (
    <Container>
      <Block>
        {/* <UsedLicenses /> */}
        <BackButton onClick={() => navigate(-1)}>« {trans('BACK_TO_OVERVIEW')}</BackButton>
        <div dangerouslySetInnerHTML={{ __html: impressumhtml }} />
      </Block>
      <Helmet title={impressumtitel} />
    </Container>
  );
}
