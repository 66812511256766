import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import SearchObjectList from '../components/SearchObjectList';
import {
  resetSearch,
  setQuerystring,
  doSearch,
  // changeActual,
  unsetBackFromDetail,
} from '../actions/search';
import doMultiDbQueries from '../actions/doMultiDbQueries';
import getNavigatorLanguage from '../actions/getNavigatorLanguage';
import { build_selected_everything_for_query } from './locationparameterparserUtils';
import trans from '../components/trans';

const Container = styled.div`
  background-color: #fff;
`;

const Block = styled.div`
  max-width: 1280px;
  margin: 0 auto;
`;

// replace the redux  and pass the searchCache in context for all other components?
class SearchCache {
  constructor() {
    this.cache = {};
  }

  getFromCache(databases, param) {
    return this.cache[`${databases.toString()}-${param}`];
  }

  addToCache(databases, param, data) {
    this.cache[`${databases.toString()}-${param}`] = data;
  }
}

const cache = new SearchCache();

export default function SearchResultsPageComponent() {
  const search = useSelector((state) => state.search);
  const pageConfigSearchDatabases = useSelector((state) => state.config.search.databases);

  const dispatch = useDispatch();

  const [searchparams] = useSearchParams()
  const q = searchparams.get("q")
  const selected_doctypes = searchparams.get("selected_doctypes")
  const selected_authors = searchparams.get("selected_authors")
  const selected_states = searchparams.get("selected_states")

  const requestType = useMemo(() => search.allResults.type, [search.allResults.type]);

  useEffect(() => {
    const currentQuery = search.query;
    const databases = pageConfigSearchDatabases;

    if (currentQuery !== q) {
      dispatch(resetSearch({ query: q }));
      return;
    } else {
      dispatch(setQuerystring(q));
    }
    const language = getNavigatorLanguage();
    const cacheItem = cache.getFromCache(databases, `${language} ${q}`);

    const doSearchExtraParams = {
      query: q,
      ...build_selected_everything_for_query(selected_doctypes, selected_authors, selected_states),
    };

    if (cacheItem) {
      dispatch(
        doSearch({
          parsedResponse: cacheItem,
          ...doSearchExtraParams,
        })
      );
    } else {
      const RFMscriptParam = `${language} ${q}`;
      doMultiDbQueries({
        databases,
        RFMscriptParam,
      }).then(({ parsedResponse }) => {
        cache.addToCache(databases, RFMscriptParam, parsedResponse);
        dispatch(
          doSearch({
            parsedResponse,
            ...doSearchExtraParams,
          })
        );
      });
    }
    dispatch(unsetBackFromDetail());
  }, [
    search.query,
    requestType,
    pageConfigSearchDatabases,
    dispatch,
    q,
    selected_doctypes,
    selected_authors,
    selected_states,
  ]);

  return (
    <Container>
      <Block>
        <SearchObjectList isGlobalSearchresultsView />
        <Helmet title={`${trans('breadcrumb.search')}: ${q}`} />
      </Block>
    </Container>
  );
}
