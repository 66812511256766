import styled from 'styled-components';

const BannerComponent = styled.div`
  .BannerComponent {
    .BannerComponent-Top {
      text-align: center;

      .BannerComponent-Banner {
        position: relative;
        display: inline-block;
      }
    }

    .BannerComponent-Right {
      flex: 2;
      /*text-align: right;*/
    }

    .BannerComponent-Banner {
      margin-bottom: 20px;
      margin-top: 20px;
    }
    @media screen and (max-width: 992px) {
      .BannerComponent-Banner {
        margin-top: 20px;
        margin-bottom: 20px;
      }
    }

    .BannerComponent-Ontop {
      width: 100%;
      position: fixed;
      bottom: 0;
      left: 0;
      z-index: 9999;

      .BannerComponent-Close {
        display: block;
        padding: 10px;
      }
      .BannerComponent-Banner {
        margin-bottom: 0;
      }

      img {
        margin-left: auto;
        margin-right: auto;
        display: block;
        max-width: 100%;
        height: auto;
      }
    }
  }
`;
export default BannerComponent;
