import React from 'react';
import querystring from 'query-string';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Section from './Section';

import { translatedTextForObject } from './trans';

export const Entry = styled.div`
  padding: ${({ isMobilePage }) => (isMobilePage ? '5px 10px' : '10px')};
  background-color: white;
  border: grey;
  margin: 5px 0px;
  font-weight: ${({ color }) => (color ? 'bold' : 'normal')};
  &:nth-child(even) {
    background-color: ${({ isMobilePage }) => isMobilePage && 'grey'};
  }
`;

function EntriesList({ list, title, toUrl, moreText, isMobilePage }) {
  if (!list) {
    return null;
  }
  return (
    <div style={{ marginBottom: 30, marginRight: 15 }}>
      <Section>{translatedTextForObject(title)}</Section>
      {list.map((entry) => (
        <Link
          style={{ textDecoration: 'none' }}
          to={{
            pathname: toUrl,
            search: querystring.stringify({ selectedItem: entry.id }),
          }}
        >
          <Entry>
            <img
              style={{
                marginRight: 10,
                maxWidth: 100,
                maxHeight: 50,
                width: 'auto',
                height: 'auto',
              }}
              src={entry.icon}
              alt={entry.title}
            />{' '}
            {entry.title}
          </Entry>
        </Link>
      ))}
      <Link to={toUrl}>
        <Entry isMobilePage={isMobilePage} color="#8fa2c4">
          {translatedTextForObject(moreText)} >
        </Entry>
      </Link>
    </div>
  );
}

export default EntriesList;
