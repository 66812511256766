import stringFormat from 'string-format';
import backendQuery from '../actions/backendQuery';

function parseResponse(results) {
  const doctypes = results
    .map((item) => item.element.n004t_Typ)
    .reduce((p, c) => {
      if (p.indexOf(c) === -1) {
        p.push(c);
      }
      return p;
    }, []);
  const authors = results
    .map((item) => item.element.n020t_Anzeige_Suche_Autor)
    .reduce((p, c) => {
      if (p.indexOf(c) === -1) {
        p.push(c);
      }
      return p;
    }, []);

  return {
    doctypes,
    authors,
    responseResults: results,

    // TODO: remove these!
    selected_states: ['1', '2'],

    selected_authors: [],
    selected_doctypes: [],
    backFromDetailView: false,
    loaded: false,
  };
}

export default function doMultiDbQueries({
  databases,
  RFMscriptParam,
  RFMscript = 'RESTSearchSort',
}) {
  const promises = databases.map((db) =>
    backendQuery({
      url: db.url.indexOf('{0}') >= 0 ? stringFormat(db.url, db.name) : db.url,
      RFMscriptParam,
      RFMscript,
      RFMmax: 1000,
    })
  );

  return Promise.all(promises).then(
    (responses) => {
      // databases[1].name = 'seconddb'; // experiment with multidb without second db
      const results = responses
        .reduce(
          (res, response, index) => [
            ...res,
            ...(response.data || []).map((item, idx) => ({
              db: databases[index].name,
              element: { ...item, id: response.meta[idx].recordID },
            })),
          ],
          []
        )
        .reduce(
          (res, entry) =>
            res.find((e) => e.db === entry.db && e.element.id === entry.element.id)
              ? res
              : [...res, entry],
          []
        );
      return { parsedResponse: parseResponse(results) };
    },
    (error) => {
      console.log('REQUEST ERROR', error && error.errorMessage ? error.errorMessage : error);
      return { parsedResponse: parseResponse([]) };
    }
  );
}
