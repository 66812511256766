import sprintf from 'sprintf';

const tStrings = {
  'searchlist.A11': {
    de: 'SafetyNews',
    en: 'SafetyNews',
    zh: '安全新闻',
    ja: 'セーフティーニュース',
  },
  'searchlist.B10': {
    de: 'Anforderungen',
    en: 'Requirements',
    zh: '要求项目',
    ja: '要求',
  },
  'searchlist.B01': {
    de: 'Lastfälle',
    en: 'Load Cases',
    zh: '载荷工况',
    ja: 'ロードケース',
  },
  'searchlist.B07': {
    de: 'Regionen',
    en: 'Regions',
    zh: '国家地区',
    ja: '地域',
  },
  'searchlist.B04': {
    de: 'Dokumente',
    en: 'Documents',
    zh: '文件',
    ja: 'ドキュメント',
  },
  'searchlist.B02': {
    de: 'Organisationen',
    en: 'Organizations',
    zh: '组织机构',
    ja: '組織',
  },
  'searchlist.B11': {
    de: 'Produkte',
    en: 'Products',
    zh: '产品',
    ja: 'プロダクト',
  },
  'searchlist.B06': {
    de: 'Dummies',
    en: 'Dummies',
    zh: '假人',
    ja: 'ダミー',
  },
  'searchlist.B14': {
    de: 'Training',
    en: 'Training',
    zh: '培训课程',
    ja: 'トレーニング',
  },
  'objectlistheader.validfrom': {
    de: 'gültig ab',
    en: 'valid from',
    zh: '生效日期',
    ja: '発効日',
  },
  'objectlistheader.established': {
    de: 'Gegründet',
    en: 'Established',
    zh: '創辦',
    ja: '創設さ',
  },
  'objectlistheader.author': {
    de: 'Autor',
    en: 'Author',
    zh: '作者',
    ja: '作成者',
  },
  'objectlistheader.eventdate': {
    de: 'Datum',
    en: 'Date',
    zh: '日期',
    ja: '日付',
  },
  'objectlistheader.host': {
    de: 'Veranstalter',
    en: 'Host',
    zh: '組織者',
    ja: '主催者',
  },
  'objectlistheader.manufacturer': {
    de: 'Hersteller',
    en: 'Manufacturer',
    zh: '生產廠家',
    ja: '製作者',
  },
  'objectlistheader.publicationdate': {
    de: 'Datum',
    en: 'Date',
    zh: '日期',
    ja: '日付',
  },
  'objectlistheader.authorsource': {
    de: 'Quelle',
    en: 'Source',
    zh: '源頭',
    ja: '源泉',
  },
  'share.text': {
    de: 'Sehr geehrte Damen und Herren,\n\nich denke dieser Artikel könnte Sie interessieren:',
    en: '	Dear Sir or Madam,\n\nI think this article could be interesting for you:',
    zh: '親愛的先生或女士,\n\n我認為這篇文章可能對你很有趣:',
    ja: '親愛なる、またはマダム,\n\n私はこの記事が面白いかもしれないと思う:',
  },
  'footer.about': {
    de: 'Über',
    en: 'About',
    zh: '模樣',
    ja: 'およそ',
  },
  'banner.advert': {
    de: 'Anzeige',
    en: 'Advert',
    zh: '廣告',
    ja: '広告',
  },
  'banner.close': {
    de: 'Anzeige schliessen',
    en: 'Close advert',
    zh: '關閉廣告',
    ja: '広告を閉じる',
  },
  'impressum.adverts': {
    de: 'Anzeigen',
    en: 'Adverts',
    zh: '廣告',
    ja: '広告',
  },
  'news.author': {
    de: 'Autor/Quelle',
    en: 'Author/Source',
    zh: '作者/來源',
    ja: '著者/ソース',
  },
  'news.more_information': {
    de: 'Mehr Informationen',
    en: 'More Information',
    zh: '更多信息',
    ja: '詳しくは',
  },
  'impressum.other': {
    de: 'Sonstiges',
    en: 'Other matters',
    zh: '其他事項',
    ja: '他事',
  },
  'impressum.usedlicenses': {
    de: 'Verwendete Open Source Lizenzen',
    en: 'Used open source licenses',
    zh: '使用開源許可證',
    ja: '使用されているオープンソースライセンス',
  },
  'impressum.contact': {
    de: 'Kontakt',
    en: 'Contact',
    zh: '聯繫',
    ja: '接触',
  },
  'impressum.pressrelease': {
    de: 'Pressemitteilungen',
    en: 'Press releases',
    zh: '綜合報導',
    ja: 'プレスルルース',
  },
  'impressum.terms': {
    de: 'Nutzungsbedingungen',
    en: 'Terms of Use',
    zh: '使用條款',
    ja: '利用規約',
  },
  'impressum.phone': {
    de: 'Telefon',
    en: 'Phone',
    zh: '電話',
    ja: '電話',
  },
  'impressum.director': {
    de: 'Geschäftsführer',
    en: 'Managing Director',
    zh: '經理',
    ja: '取締役社長',
  },
  'impressum.content.responsible': {
    de: 'Inhaltlich verantwortlich',
    en: 'Content responsibility',
    zh: '負責內容',
    ja: 'コンテンツを担当する',
  },
  'impressum.vat': {
    de: 'Umsatzsteuer-ID',
    en: 'Value Added Tax Identification',
    zh: '增值稅識別',
    ja: '付加価値税識別',
  },
  'impressum.commercial_register.title': {
    de: 'Handelsregister',
    en: 'Commercial Register',
    zh: '商業登記',
    ja: '商業登記',
  },
  'impressum.commercial_register.content': {
    de: 'HRB 9961 des Amtsgerichts Aschaffenburg',
    en: 'HRB 9961 of the Local Court Aschaffenburg',
    zh: '地區法院阿沙芬堡的HRB 9961',
    ja: '地方裁判所AschaffenburgのHRB 9961',
  },
  'menu.requirements': {
    de: 'ANFORDERUNGEN',
    en: 'REQUIREMENTS',
    zh: '要求项目',
    ja: '要求',
  },
  RESULTS_FOUND: {
    de: 'Ergebnisse gefunden',
    en: 'Results found',
    zh: '結果發現',
    ja: '結果が見つかりました',
  },
  RESULTS_CURRENT: {
    de: 'aktuelle',
    en: 'current',
    zh: '當今',
    ja: '現在',
  },
  'menu.safetynews': {
    de: 'SAFETYNEWS',
    en: 'SAFETYNEWS',
    zh: '安全新闻',
    ja: 'セーフティーニュース',
  },
  'menu.products': {
    de: 'PRODUKTE',
    en: 'PRODUCTS',
    zh: '产品',
    ja: 'プロダクト',
  },
  'menu.trainings': {
    de: 'TRAININGS & EVENTS',
    en: 'TRAININGS & EVENTS',
    zh: '培訓和活動',
    ja: 'トレーニング＆イベント',
  },
  'menu.login': {
    de: 'Einloggen',
    en: 'Login',
    zh: '登錄',
    ja: 'ログイン',
  },
  'menu.info_login': {
    de: 'Einloggen',
    en: 'Login',
  },
  'menu.logged': {
    de: 'Eingeloggt',
    en: 'logged',
    zh: '登錄',
    ja: 'ログイン',
  },
  'menu.logout': {
    de: 'Ausloggen',
    en: 'Logout',
    zh: '登出',
    ja: 'ログアウト',
  },
  'menu.login-failed': {
    de: 'Anmeldung fehlgeschlagen',
    en: 'Login failed',
    zh: '登录失败',
    ja: 'ログインに失敗しました',
  },
  'menu.logging-in': {
    de: 'Melde an...',
    en: 'Logging in...',
    zh: '在登录',
    ja: 'ログイン',
  },
  'breadcrumb.search': {
    de: 'Suche',
    en: 'Search',
    zh: '搜索',
    ja: 'サーチ',
  },
  'loader.loading': {
    de: 'Lade...',
    en: 'Loading...',
    zh: '載入中...',
    ja: '読み込み中...',
  },
  'loader.loadingsearch': {
    de: 'Lade Sucherergebnisse für',
    en: 'Loading search results for',
    zh: '載入中...',
    ja: '読み込み中...',
  },
  'breadcrumb.object-single': {
    de: 'Einzelansicht',
    en: 'Single view',
    zh: '單一視圖',
    ja: 'シングルビュー',
  },
  'search.search_btn': {
    de: 'Suche',
    en: 'Search',
    zh: '搜索',
    ja: 'サーチ',
  },
  'search-preview': {
    de: 'Suche...',
    en: 'Search...',
    zh: '搜索',
    ja: 'サーチ',
  },
  'search.minlengthrequired': {
    de: 'Bitte geben Sie mindestens 3 Zeichen ein um eine Suche zu starten',
    en: 'Please enter a minimum of 3 characters to search',
    zh: '請輸入至少3個字符進行搜索',
    ja: '検索には最低3文字を入力してください',
  },
  'news-list.load-more-button': {
    de: 'Mehr laden',
    en: 'Load more',
    zh: '裝載更多',
    ja: 'もっと読み込む',
  },
  'search.no-results': {
    de: 'Wir konnten leider keine Inhalte finden die zu Ihrer Suchanfrage passen.',
    en: 'We could not find any matching documents for your request.',
    zh: '我們無法找到您的請求的任何匹配文檔。',
    ja: 'お客様のご要望に一致する書類は見つかりませんでした。',
  },
  TODAY: {
    de: 'Heute',
    en: 'Today',
    zh: '今天',
    ja: '今日',
  },
  YESTERDAY: {
    de: 'Gestern',
    en: 'Yesterday',
    zh: '昨天',
    ja: '昨日',
  },
  BACK_TO_OVERVIEW: {
    de: 'Zurück zur Übersicht',
    en: 'Back to overview',
    zh: '回到概述',
    ja: '概要に戻る',
  },
  'b01-opponent': {
    de: 'Gegner',
    en: 'Opponent',
    zh: '對手',
    ja: '相手',
  },
  GOOGLE_ANALYTICS_INFO: {
    de: 'Sie können die Erfassung durch Google Analytics verhindern, indem Sie auf folgenden Link klicken. Es wird ein Opt-Out-Cookie gesetzt, das die zukünftige Erfassung Ihrer Daten beim Besuch dieser Website verhindert.',
    en: 'You can refuse the use of Google Analytics by clicking on the following link. An opt-out cookie will be set on the computer, which prevents the future collection of your data when visiting this website.',
    zh: '您可以點擊以下鏈接拒絕使用Google Analytics。 計算機上會設置一個選擇退出cookie，這會阻止您在訪問此網站時收集您的數據。',
    ja: 'Googleアナリティクスの使用を拒否するには、次のリンクをクリックしてください。 オプトアウトクッキーがコンピュータに設定されます。これにより、このウェブサイトにアクセスしたときにデータが収集されなくなります。',
  },

  ABLE_GOOGLE_ANALYTICS: {
    de: 'Google Analytics aktivieren',
    en: 'Enable Google Analytics',
    zh: '啟用Google Analytics',
    ja: 'Googleアナリティクスを有効にする',
  },
  DIABLE_GOOGLE_ANALYTICS: {
    de: 'Google Analytics deaktivieren',
    en: 'Disable Google Analytics',
    zh: '禁用Google Analytics',
    ja: 'Googleアナリティクスを無効にする',
  },
  PREVIOUS: {
    de: 'Vorherige',
    en: 'Previous',
    zh: '以前',
    ja: '前',
  },
  CURRENT: {
    de: 'Aktuelle',
    en: 'Current',
    zh: '當前',
    ja: '現在',
  },
  UPCOMING: {
    de: 'Zukünftige',
    en: 'Upcoming',
    zh: '即將到來',
    ja: '今後の予定',
  },
  SPECIFICATIONS: {
    de: 'Spezifikationen',
    en: 'Specifications',
    zh: '產品規格',
    ja: '仕様',
  },
  COOKIE_MESSAGE: {
    de: 'Diese Seite nutzt <a href=/#/privacypolicy/>Cookies</a> um die User Experience zu verbessern. Möchten Sie die Cookies behalten?',
    en: 'This site uses <a href=/#/privacypolicy/>cookies</a> to improve your browsing experience. Would you like to keep them?',
    zh: '這個網站使用 <a href=/#/privacypolicy/>cookies</a> 改善您的瀏覽體驗。 你想保留它們嗎？',
    ja: 'このサイトは <a href=/#/privacypolicy/>cookies</a> ブラウジングの経験を向上させます。 それらを保管しますか？',
  },
  SHOW_IN_BROWSER: {
    de: 'Im Browser anzeigen',
    en: 'Show in Browser',
    zh: '在瀏覽器中顯示',
    ja: 'ブラウザで表示',
  },
  CHOOSE_CATEGORIES: {
    de: 'Einschränken auf:',
    en: '',
    zh: '',
    ja: '',
  },
  SHOW_MORE: {
    de: 'Mehr anzeigen',
    en: 'show more',
    zh: '',
    ja: '',
  },
  DISABLE_ALL: {
    de: 'Alles Deaktivieren',
    en: 'Disable all',
    zh: '',
    ja: '',
  },
  FILTER: {
    de: 'Filter',
    en: 'filter',
    zh: '',
    ja: '',
  },
  CLOSE: {
    de: 'Schließen',
    en: 'Close',
    zh: '',
    ja: '',
  },
  SEARCH_TIPS: {
    de: '<p> <b>Search Tip:</b> You can use prefixes like <b>age:</b>,<b>author:</b>,<b>company:</b>, and others to narrow your search and find exactly what you need. </p>',
    en: '<p> <b>Search Tip:</b> You can use prefixes like <b>age:</b>,<b>author:</b>,<b>company:</b>, and others to narrow your search and find exactly what you need. </p>',
    zh: '<p> <b>Search Tip:</b> You can use prefixes like <b>age:</b>,<b>author:</b>,<b>company:</b>, and others to narrow your search and find exactly what you need. </p>',

    ja: '<p> <b>Search Tip:</b> You can use prefixes like <b>age:</b>,<b>author:</b>,<b>company:</b>, and others to narrow your search and find exactly what you need. </p>',
  },
  SEARCH_TIPS_MORE: {
    de: 'learn more search tips and tricks for the safetywissen website here',
    en: 'learn more search tips and tricks for the safetywissen website here',
    zh: 'learn more search tips and tricks for the safetywissen website here',
    ja: 'learn more search tips and tricks for the safetywissen website here',
  },
  SEARCH_TIPS_LINK: {
    de: 'safetywissen Search Tips and Tricks',
    en: 'safetywissen Search Tips and Tricks',
    zh: 'safetywissen Search Tips and Tricks',
    ja: 'safetywissen Search Tips and Tricks',
  },
  ADVANCED_SEACH: {
    de: 'Detaillierte Suche aktivieren',
    en: 'Advanced Search',
    zh: '',
    ja: '',
  },
  SHOW_LESS: {
    de: 'Weniger anzeigen',
    en: 'Show less',
    zh: '',
    ja: '',
  },
};

export function crossPlatformNavigatorLang() {
  const language = navigator.userLanguage ? navigator.userLanguage : navigator.language;
  return language.toLowerCase().substr(0, 2);
}

const KNOWN_LANGUAGES = ['en', 'de', 'zh', 'ja'];

const defaultLang = 'en';

function getLang() {
  const bLang = crossPlatformNavigatorLang();
  // console.log('lang', bLang);
  return KNOWN_LANGUAGES.indexOf(bLang) !== -1 ? bLang : defaultLang;
}

function getVideoParams({ youtube, china }) {
  if (china && youtube) {
    const isChina = ['zh', 'ja'].indexOf(getLang()) !== -1;
    return isChina ? { video: china, isYoutube: false } : { video: youtube, isYoutube: true };
  }
  if (youtube) {
    return { video: youtube, isYoutube: true };
  }
  if (china) {
    return { video: china, isYoutube: false };
  }
  return null;
}

export function getVideoParamsMenu({ videoyoutube_ID, videochina_ID }) {
  return getVideoParams({
    youtube: videoyoutube_ID,
    china: videochina_ID,
  });
}

export const lang = getLang();
export function translatedTextForObject(obj) {
  return obj[lang] !== undefined ? obj[lang] : obj[defaultLang];
}

export default function trans(translationId, params) {
  const str = tStrings[translationId]
    ? tStrings[translationId][lang] || tStrings[translationId][defaultLang]
    : `*${translationId}*`;
  return sprintf.sprintf(str, params);
}
