import styled from 'styled-components';

const NewsListStyle = styled.div`
  @font-size: 15px;
  @line-height: 1.5;
  @lines-to-show: 3;

  .NewsList {
    padding: 0;
    // @media (min-width: 768px) {
    //   padding: 15px;
    // }
    list-style: none;
    li.newsItem {
      border-bottom: 1px solid;
      //TOOS
      padding-left: 15px;
      @media (min-width: 768px) {
        margin-bottom: 16px;
        border-bottom: 0;
      }
      @media (max-width: 768px) {
        padding-left: 0;
      }
      &:last-child {
        margin-bottom: 0;
      }
      > a {
        color: inherit;
      }
      a:hover {
        text-decoration: none;
      }
      .newsItem-text {
        display: block; /* Fallback for non-webkit */
        display: -webkit-box;
        height: @font-size*@line-height*@lines-to-show; /* Fallback for non-webkit */
        margin: 0 auto;
        font-size: @font-size;
        line-height: @line-height;
        -webkit-line-clamp: @lines-to-show;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;

        max-width: 600px;
      }

      .newsItem-bottom {
        border-bottom: 0px solid;
        @media (min-width: 768px) {
          border-bottom: 2px solid;
        }
        
      }
      .newsItem-category {
        text-transform: uppercase;
        width: 100%
        border-bottom: 2px solid ;
        font-weight: bold;
        font-size: 12px;
        float: left;
        @media (min-width: 768px) {
          font-size: 14px;
        }
        @media (max-width: 768px) {
          border-bottom: none;
        }
      }
      .newsItem-date {
        float: right;
        font-size: 12px;
        @media (min-width: 768px) {
          float: right;
        }
      }
      .newsItem-title {
        font-size: 15px;
        font-weight: bold;
        margin: 5px 0;
        @media (min-width: 768px) {
          font-size: 20px;
        }
      }
    }
  }
`;
export default NewsListStyle;
