import { combineReducers } from 'redux';
import { newsList } from './actions/newsList';
import { object } from './actions/object';
import { search } from './actions/search';
import { searchCache } from './actions/searchCache';
import { menu } from './actions/menu';
import { config } from './actions/config';
import { footerReducer } from './actions/footer';
import { profilReduce } from './actions/profil';

const rootReducer = combineReducers({
  newsList,
  object,
  search,
  searchCache,
  menu,
  config,
  footer: footerReducer,
  profil: profilReduce,
});

export default rootReducer;
