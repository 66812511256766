import styled from 'styled-components';

const Section = styled.div`
  border-left: ${(props) => `3px solid ${props.theme.productItemTitle}`};
  padding-left: 10px;
  font-weight: bold;
  margin: ${(props) => (props.$margin ? props.$margin : '20px 0px')};
`;

export default Section;
