import React, { useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { useNavigate } from 'react-router';
import querystring from 'query-string';
import trans from './trans';
import Section from './Section';
import Checkbox from './Checkbox';

import {
  addDocState,
  removeDocState,
  addDoctype,
  removeDoctype,
  addAuthor,
  removeAuthor,
  resetFilters,
} from '../actions/search';
import { useSearchParams } from 'react-router-dom';

function getCurrentValue(locationQueryParsedPart) {
  if (Array.isArray(locationQueryParsedPart)) {
    return locationQueryParsedPart;
  }
  return [locationQueryParsedPart] || [];
}

function toggleValueInUrl(searchParams, type, value, navigate) {
  const parsedSearch = querystring.parse(searchParams);
  const currentValue = getCurrentValue(parsedSearch[type]);

  const newValue =
    currentValue.indexOf(value) !== -1
      ? currentValue.filter((y) => y !== value)
      : [...currentValue, value];

  const newQueries = { ...parsedSearch, [type]: newValue };
  const search = querystring.stringify(newQueries);
  navigate({ state: {}, search });
}

function toggleValueInUrlStates(searchParams, type, value, localStateValue, navigate) {
  const parsedSearch = querystring.parse(searchParams);

  let currentValue = getCurrentValue(parsedSearch[type]);

  if (localStateValue && localStateValue.length && currentValue && currentValue[0] === undefined) {
    return localStateValue; // special case for Status inial values not in url but should be used as default
  }

  let newValue =
    currentValue.indexOf(value) !== -1
      ? currentValue.filter((y) => y !== value)
      : [...currentValue, value];

  if (newValue.indexOf('EMPTY') !== -1) {
    // filter out "EMPTY" if a value was selected
    newValue = newValue.filter((v) => v !== 'EMPTY');
  }

  if (newValue.length === 0) {
    // console.log('now add');
    newValue = ['EMPTY']; // HACK We have to override this with a value that is visible in the url to prevent the default ['1', '2'] value
  }

  const newQueries = { ...parsedSearch, [type]: newValue };
  const search = querystring.stringify(newQueries);
  navigate({ state: {}, search });
}

const FilterContainer = styled.div`
  border-top: 1px solid #ddd;
  color: #34434a;
  padding: 10px 0px;
`;
const OptionHolder = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 0;
`;

export const FilterLabel = styled.label`
  font-weight: normal;
  margin: 0;
  color: black;
  margin-left: 5px;
`;

const FilterTitle = styled.div`
  font-weight: bold;
`;

const DisableAll = styled.div`
  margin: 20px 0px 20px 10px;
  color: #00677f;
  font-weight: bold;
  cursor: pointer;
  white-space: nowrap;
`;
const ShowMore = styled.div`
  display ${(props) => (props.$show ? 'block' : 'none')};
  color: #00677f;
  font-weight: bold;
  cursor:pointer;
`;

const FilterPaginationContainer = styled.div`
  max-height: 500px;
  overflow: auto;
`;

function FilterPagination({ totalLength, children, initialLength }) {
  const [pageLength, setPageLength] = useState(initialLength);
  return (
    <FilterPaginationContainer>
      {[...children].splice(0, pageLength)}
      <ShowMore $show={pageLength < totalLength} onClick={() => setPageLength(totalLength)}>
        + {trans('SHOW_MORE')}
      </ShowMore>
    </FilterPaginationContainer>
  );
}

function sortThings(a, b) {
  const firstString = a.toLowerCase();
  const secondString = b.toLowerCase();
  if (firstString > secondString) {
    return 1;
  } else if (firstString < secondString) {
    return -1;
  }
  return 0;
}

function CompanyDropdown({ searchParams, authors, selected_authors }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const items = authors
    .sort(sortThings)
    .filter((author) => author) // must have value
    .map((author) => ({
      value: author,
      label: author,
      selected: selected_authors.indexOf(author) !== -1,
    }));

  return (
    <FilterContainer>
      <FilterTitle>Company/Author</FilterTitle>
      <FilterPagination totalLength={items.length} initialLength={5}>
        {items.map((item, index) => (
          <OptionHolder key={index}>
            <Checkbox
              onChange={() => {
                toggleValueInUrl(searchParams, 'selected_authors', item.value, navigate);
                const action = item.selected ? removeAuthor(item.value) : addAuthor(item.value);
                dispatch(action);
              }}
              checked={item.selected}
              id={item.value}
              value={item.value}
            />
            <FilterLabel htmlFor={item.value}>{item.label}</FilterLabel>
          </OptionHolder>
        ))}
      </FilterPagination>
    </FilterContainer>
  );
}

function getNummberOfElements(list, index) {
  if (!list) return 0;
  return list.filter((element) => element.element.f025b_Aktuell === index).length;
}

function DocumentStateDropdown({ searchParams, allResults }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const docStates = useSelector((state) => state.search.selected_states || []);

  const previousNumber = getNummberOfElements(allResults, '0');
  const currentNumber = getNummberOfElements(allResults, '1');
  const upcomingNumber = getNummberOfElements(allResults, '2');
  const data = [
    {
      value: '0',
      label: `${trans('PREVIOUS')} (${previousNumber})`,
      selected: docStates.indexOf('0') !== -1,
    },
    {
      value: '1',
      label: `${trans('CURRENT')} (${currentNumber})`,
      selected: docStates.indexOf('1') !== -1,
    },
    {
      value: '2',
      label: `${trans('UPCOMING')} (${upcomingNumber})`,
      selected: docStates.indexOf('2') !== -1,
    },
  ];

  return (
    <FilterContainer>
      <FilterTitle>Status</FilterTitle>
      {data.map((item) => (
        <OptionHolder key={item.value}>
          <Checkbox
            onChange={() => {
              toggleValueInUrlStates(searchParams, 'selected_states', item.value, docStates, navigate);
              const action = item.selected ? removeDocState(item.value) : addDocState(item.value);
              dispatch(action);
            }}
            id={item.value}
            checked={item.selected}
            value={item.value}
          />
          <FilterLabel htmlFor={item.value}>{item.label}</FilterLabel>
        </OptionHolder>
      ))}
    </FilterContainer>
  );
}

function TypeDropdown({ searchParams, doctypes, selected_doctypes }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const items = useMemo(
    () =>
      doctypes
        .sort(sortThings)
        .filter((type) => type) // must have value
        .map((type) => ({
          value: type,
          label: type,
          // eslint-disable-next-line no-unneeded-ternary
          selected: selected_doctypes.indexOf(type) !== -1 ? true : false,
        })),
    [doctypes, selected_doctypes]
  );

  return (
    <FilterContainer>
      <FilterTitle>Type</FilterTitle>
      <FilterPagination totalLength={items.length} initialLength={5}>
        {items.map((item) => (
          <OptionHolder key={item.value}>
            <Checkbox
              onChange={() => {
                toggleValueInUrl(searchParams, 'selected_doctypes', item.value, navigate);
                const action = item.selected ? removeDoctype(item.value) : addDoctype(item.value);
                dispatch(action);
              }}
              id={item.value}
              checked={item.selected}
              value={item.value}
            />
            <FilterLabel htmlFor={item.value}>{item.label}</FilterLabel>
          </OptionHolder>
        ))}
      </FilterPagination>
    </FilterContainer>
  );
}

const DivWrapper = styled.div`
  margin-bottom: 15px;
  width: 90%;
`;

const DivFlex = styled.div`
  display: flex;
`;

export default function ObjectListFacets({
  doctypes,
  selected_doctypes,
  authors,
  selected_authors,
  allResultsForSearchKey,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const searchParams = useSearchParams()
  // console.log('searchParams', searchParams)

  const resetFiltersFn = useMemo(
    () => () => {
      const { q } = querystring.parse(searchParams);
      navigate({
        search: querystring.stringify({ q }),
      });
      dispatch(resetFilters());
    },
    [searchParams, navigate, dispatch]
  );

  return (
    <DivWrapper>
      <DivFlex>
        <Section>Filter</Section>
        <DisableAll onClick={resetFiltersFn}>{trans('DISABLE_ALL')}</DisableAll>
      </DivFlex>
      <span>
        {doctypes ? (
          <TypeDropdown
            searchParams={searchParams}
            selected_doctypes={selected_doctypes}
            doctypes={doctypes}
          />
        ) : null}
      </span>
      <span>
        {authors ? (
          <CompanyDropdown
            searchParams={searchParams}
            selected_authors={selected_authors}
            authors={authors}
          />
        ) : null}
      </span>
      <span>
        <DocumentStateDropdown
          searchParams={searchParams}
          allResults={allResultsForSearchKey}
        />
      </span>
    </DivWrapper>
  );
}
