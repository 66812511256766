import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import {
  mapResponseData,
  getTop,
  getSideBanner,
  getMobileBanner,
  getBackBanner,
  getHead,
} from './bannersUtils';

import backendQuery from '../actions/backendQuery';
import BannerStoreContext from '../context/BannerStore';

export default function BannerStoreContextProvider({ children }) {
  const { bannerURL, bannerRFMScript, bannerRFMscriptParamAppend, bannerWithLanguage } =
    useSelector((state) => state.config);

  const [closedBanner, setCloseBanner] = useState(false);
  const [banners, setBanners] = useState([]);

  const top = useMemo(() => getTop(banners), [banners]);
  const side = useMemo(() => getSideBanner(banners), [banners]);
  const mobile = useMemo(() => getMobileBanner(banners), [banners]);
  const back = useMemo(() => getBackBanner(banners), [banners]);
  const head = useMemo(() => getHead(banners), [banners]);

  useEffect(() => {
    if (bannerURL) {
      backendQuery({
        url: bannerURL,
        RFMscript: `${bannerRFMScript}${bannerRFMscriptParamAppend}`,
        noLanguage: !bannerWithLanguage,
      }).then((resp) => {
        setBanners(mapResponseData(resp));
      });
    }
  }, [bannerRFMScript, bannerRFMscriptParamAppend, bannerURL, bannerWithLanguage]);
  const isTop = useMemo(() => top && top.length > 0, [top]);

  const value = useMemo(
    () => ({ top, side, mobile, back, head, isTop, closedBanner, setCloseBanner }),
    [top, side, mobile, back, head, isTop, closedBanner]
  );

  return <BannerStoreContext.Provider value={value}>{children}</BannerStoreContext.Provider>;
}
