import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import trans from '../components/trans';
import get from '../actions/api';
import { useSearchParams } from 'react-router-dom';

async function getRedirectUrl(localLoginUrlBase) {
  return get({ url: `${localLoginUrlBase}infosgetredirecturl`, query: {} });
}

async function getAccessTokenFromCode(code, localLoginUrlBase) {
  // return { token: 'LOCALHOSTDUMMYTOKENVALID' }
  return get({ url: `${localLoginUrlBase}infoscodeverify`, query: { code } });
}

export default function InfosLogin() {
  const { loginAndRegistration, newsList: { tabs }, } = useSelector(state => state.config)
  const { localLoginUrlBase } = loginAndRegistration

  const [loginFailed, setLoginFailed] = useState(false);

  // TODO: continue here!
  const [searchParams] = useSearchParams()
  const code = searchParams.get("code")

  useEffect(() => {
    const execute = async () => {
      if (code) {
        try {
          const accesssTokenResponse = await getAccessTokenFromCode(
            code,
            localLoginUrlBase
          );
          localStorage.setItem('infos_access_token', accesssTokenResponse.token);
          const firstTab = tabs[0].name.de;
          window.location = `/news/${firstTab}`;
        } catch (e) {
          // Note: Print error message to debugging console
          // eslint-disable-next-line
          console.warn(e);
          setLoginFailed(true);
        }
        return;
      }

      try {
        const redirectUrl = await getRedirectUrl(localLoginUrlBase);
        window.location = redirectUrl.url;
      } catch (e) {
        // Note: Print error message to debugging console
        // eslint-disable-next-line
        console.warn(e);
        setLoginFailed(true);
      }
    };
    execute();
  }, [localLoginUrlBase, code, tabs]);

  if (loginFailed) {
    return <div className="ma2 b tc">{trans('menu.login-failed')}</div>;
  }
  return <div className="ma2 b tc">{trans('menu.logging-in')}</div>;
}
