// const tagsMockData = {
//   tags: `"tags": [
//       { "tag": "Euro NCAP", "color": "#23B428" },
//       { "tag": "Child protection", "color": "black" },
//       { "tag": "Euro NCAP", "color": "#23B428" },
//       { "tag": "Child protection", "color": "black" },
//       { "tag": "Euro NCAP", "color": "#23B428" },
//       { "tag": "Child protection", "color": "black" },
//       { "tag": "Euro NCAP", "color": "#23B428" },
//       { "tag": "Child protection", "color": "black" }
//     ]`,
// };

function tagsConvert(tags) {
  if (tags !== '') {
    const formatTags = tags.replace('"tags":', '');
    try {
      return JSON.parse(formatTags);
    } catch (e) {
      return [];
    }
    // return tags;
  }
  return tags;
}

export function mapResposeData(data) {
  const unsortedData = (data || []).map((item) => ({
    guid: item.a001t_GUID,
    date: item.f001d_ErstelltAm,
    title: item.n100t_Titel,
    text: item.f003t_Newstext,
    imagePreview: item.f010t_Bild_URL_klein,
    image: item.f011t_Bild_URL_gross,
    category: item.f012t_Kategorie,
    autor: item.f013t_Autor,
    webshort: item.f005t_Webkurzname,
    url: item.f006t_Url,
    timestamp: item.n005ts_Zeitstempel,
    tags: tagsConvert(item.f024t_Tags),
    //  For test purposes you can use the mock data
    //  tags: tagsConvert(tagsMockData.tags),
  }));
  return unsortedData.sort((first, second) => {
    const date1 = new Date(first.timestamp);
    const date2 = new Date(second.timestamp);
    if (date1 < date2) {
      return 1;
    }
    if (date1 > date2) {
      return -1;
    }
    return 0;
  });
}
