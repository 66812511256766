import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const BackLink = styled(Link)`
  display: inline-block;
  margin-bottom: 10px;
  padding: 10px;
  font-weight: 40px;
  text-decoration: none !important;
  color: ${(props) => props.theme.backButtonColor};
  border: ${(props) => `1px solid ${props.theme.backButtonColor}`};
`;
const BackButton = styled.button`
  display: inline-block;
  margin-bottom: 10px;
  padding: 10px;
  background-color: transparent;
  font-weight: 40px;
  text-decoration: none !important;
  color: ${(props) => props.theme.backButtonColor};
  border: ${(props) => `1px solid ${props.theme.backButtonColor}`};
`;

export default function BackButtonLink({ to, onClick, children }) {
  if (onClick) {
    return <BackButton onClick={onClick}>{children}</BackButton>;
  }
  return <BackLink to={to}>{children}</BackLink>;
}
