import superagent from 'superagent';

function callback(resolve, reject, error, response) {
  if (error && error.response) {
    reject({ errorMessage: error.response.body || error.response.error.message });
    return;
  }
  if (error && error.message) {
    reject({ errorMessage: error.message });
    return;
  }
  if (response && response.error) {
    reject({ errorMessage: response.error });
    return;
  }
  resolve(response.body);
}

function doGet({ url, query = {} }, resolve, reject) {
  if (!url) {
    throw new Error('url required!');
  }
  // const token = localStorage.getItem('login_token');
  return (
    superagent
      .get(url)
      .query(query)
      //  .set('Authorization', token ? 'token ' + token : undefined)
      //   .set('Accept', 'application/json')
      .end((error, response) => callback(resolve, reject, error, response))
  );
}

export default function get({ url, query = {} }) {
  return new Promise((resolve, reject) => doGet({ url, query }, resolve, reject));
}
