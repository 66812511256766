import apiGet from '../actions/api';
import { lang } from '../components/trans';

export default function backendQuery({ url, noLanguage, ...rest }) {
  const query = noLanguage ? rest : { RFMscriptParam: lang, ...rest };
  return apiGet({
    url,
    query,
  });
}
