import { createAction, handleActions } from 'redux-actions';

const SET_CONFIG = 'SET_CONFIG';
const SET_ROUTE = 'SET_ROUTE';

export const setRoute = createAction(SET_ROUTE);
export const setConfig = createAction(SET_CONFIG);

export const config = handleActions(
  {
    [SET_CONFIG]: (state, action) => action.payload,
    [SET_ROUTE]: (state, action) => ({ ...state, route: action.payload }),
  },
  {}
);
