import React, { useMemo, useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import querystring from 'query-string';
import styled from 'styled-components';

import trans, { crossPlatformNavigatorLang } from './trans';
import { unsetBackFromDetail } from '../actions/search';
import { SearchInput } from './SearchObjectList';
import Checkbox from './Checkbox';
import SuggestionFilter from './SuggestionFilter';

function useKeyboardEvent(key, callback) {
  useEffect(() => {
    const handler = function (event) {
      if (event.key === key) {
        callback();
      }
    };
    window.addEventListener('keydown', handler);
    return () => {
      window.removeEventListener('keydown', handler);
    };
  }, [callback, key]);
}

// const SearchText = styled.div`
//   @media (max-width: ${props => props.theme.navbarBreakPoint}) {
//     display: none;
//   }
// `;

const searchCategories = [
  'Documents',
  'Load Cases',
  'Requirements',
  'SafetyNews',

  // 'Products',
  // 'Dummies',
  // 'Organizations',
  // 'Training',
];

export const FilterLabel = styled.label`
  font-weight: normal;
  margin: 0;
  color: black;
  margin-left: 5px;
`;

const ExpandedSearchContainer = styled.div`
  border-bottom: ${(props) => `3px solid ${props.theme.NavBarBorderBottom}`};
  border-top-color: ${(props) => props.theme.navBarBorderColor};
  position: absolute;
  background-color: white;
  top: 0px;
  left: 0px;
  width: 100%;
  z-index: 100;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SearchContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 5px;
  cursor: pointer;
  margin: 5px 10px;
  @media (max-width: ${(props) => props.theme.navbarBreakPoint}) {
    padding: 0px;
  }
  .glyphicon-search:before {
    font-size: 20px;
  }
`;

const CategorieFilter = styled.div`
  padding: 8px;
  border: 1px solid #ddd;
  margin: 5px 5px 5px 0px;
  cursor: pointer;
  background-color: ${(props) => (props.selected ? '#f4f6f9' : 'white')};
  @media (max-width: ${(props) => props.theme.navbarBreakPoint}) {
    padding: 5px;
  }
  :hover {
    background: #f4f6f9;
  }
`;

const CategoriesContainer = styled.div`
  display: flex;
  @media (max-width: ${(props) => props.theme.navbarBreakPoint}) {
    flex-direction: column;
  }
`;

const SearchButton = styled.button`
  height: 50px;
  margin-left: 10px;
`;

function RenderCategory({ cat, toogleCategorie, selected }) {
  const onClick = useCallback(() => toogleCategorie(cat), [cat, toogleCategorie]);
  const onChange = useCallback(() => null, []);
  return (
    <CategorieFilter selected={selected} onClick={onClick}>
      <Checkbox checked={selected} id={cat} onChange={onChange} />
      <FilterLabel>{cat}</FilterLabel>
    </CategorieFilter>
  );
}

function SelectCategory({ selected_doctypes, toogleCategorie }) {
  return (
    <React.Fragment>
      {trans('CHOOSE_CATEGORIES')}
      <CategoriesContainer>
        {searchCategories.map((c) => (
          <RenderCategory
            selected={selected_doctypes.indexOf(c) !== -1}
            toogleCategorie={toogleCategorie}
            cat={c}
            key={c}
          />
        ))}
      </CategoriesContainer>
    </React.Fragment>
  );
}
export default function SearchBar() {
  const theme = useSelector(state => state.config.theme)
  const placeholder = useSelector(state => state.config.searchPlaceholder[crossPlatformNavigatorLang()])

  const navigate = useNavigate()
  const { pathname, search } = useLocation();
  const dispatch = useDispatch()

  const locationQuery = useMemo(() => querystring.parse(search), [search]);

  const [isEpanded, setExpanded] = useState(false);
  const [selected_doctypes, setSelectedDoctypes] = useState([]);

  const [query, setQuery] = useState((locationQuery && locationQuery.q) || '');

  const toogleCategorie = (c) => {
    const categorieIndex = selected_doctypes.indexOf(c);
    if (categorieIndex === -1) {
      setSelectedDoctypes([...selected_doctypes, c]);
    } else {
      selected_doctypes.splice(categorieIndex, 1);
      setSelectedDoctypes([...selected_doctypes]);
    }
  };

  const resetState = () => {
    setExpanded(false);
    setQuery('');
    setSelectedDoctypes([]);
  };

  useKeyboardEvent('Escape', () => {
    resetState();
  });

  const addFilter = (newFilter) => {
    if (query.indexOf(newFilter) === -1) {
      setQuery(newFilter);
    }
  };

  if (pathname.indexOf('/search') !== -1) {
    return null;
  }
  if (isEpanded) {
    return <form
      onSubmit={(event) => {
        event.preventDefault();
        const q = query.trim();
        if (q.length <= 2) {
          // eslint-disable-next-line no-alert
          window.alert(trans('search.minlengthrequired'));
          return;
        }
        navigate({
          state: {},
          pathname: '/search/',
          search: querystring.stringify({
            q,
            selected_doctypes,
            type: 'A11',
            field: 'f036t_Gueltigkeitsdatum_ISO',
            _k: 'xte',
            desc: true,
          }),
        });
        if (locationQuery && locationQuery.prev === '/search/') {
          dispatch(unsetBackFromDetail());
        }
        resetState();
      }}
    >
      <ExpandedSearchContainer theme={theme}>
        <div style={{ display: 'flex', flexDirection: 'column', maxWidth: 960 }}>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <SearchInput
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              placeholder={placeholder}
              autoFocus
            />
            <SearchButton className="btn btn-success" type="submit">
              {trans('search.search_btn')}
            </SearchButton>
            <SearchButton
              onClick={() => {
                resetState();
              }}
              type="button"
              className="btn btn-default"
            >
              X
            </SearchButton>
          </div>
          <SelectCategory toogleCategorie={toogleCategorie} selected_doctypes={selected_doctypes} />
          <SuggestionFilter addFilter={addFilter} />
        </div>
      </ExpandedSearchContainer>
    </form>
  }
  return (
    <SearchContainer onClick={() => setExpanded(true)}>
      <span className="glyphicon glyphicon-search" style={{ color: '#999' }} />
      {/* <SearchText> {trans('search-preview')}</SearchText> */}
    </SearchContainer>
  );
}
