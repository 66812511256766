import React from 'react';
import { useCookies } from 'react-cookie';
import trans from './trans';

const crossHeavy = '✖️'
const ok = '✔'

function dateInXDays(days) {
  const date = new Date();
  const inMs = days * 24 * 60 * 60 * 1000;
  date.setTime(date.getTime() + inMs);
  return date;
}
export default function CookieQuestion({ theme }) {
  const [cookies, setCookie] = useCookies(['Opt-Out-Cookie']);

  if (typeof cookies['Opt-Out-Cookie'] !== "undefined") {
    return null;
  }

  return (
    <div
      style={{
        width: '100%',
        backgroundColor: theme.cookiesBg,
        textAlign: 'center',
        fontSize: '14px',
        top: '0',
        zIndex: '10000000',
        padding: '5px 0px',
        position: 'relative',
      }}
    >
      <span
        style={{ color: theme.cookiesText }}
        dangerouslySetInnerHTML={{ __html: trans('COOKIE_MESSAGE') }}
      />
      <span
        style={{ color: 'green', marginLeft: '5px', cursor: 'pointer', fontSize: '20px' }}
        onClick={() => {
          setCookie('Opt-Out-Cookie', true, { path: '/', expires: dateInXDays(365) });
          window.location.reload();
        }}
      >
        {ok}
      </span>
      <span
        style={{ color: 'red', marginLeft: '5px', cursor: 'pointer', fontSize: '20px' }}
        onClick={() => {
          setCookie('Opt-Out-Cookie', false, { path: '/', expires: dateInXDays(60) });
          window.location.reload();
        }}
      >
        {crossHeavy}
      </span>
    </div>
  );
}
