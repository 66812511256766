import React, { useMemo } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
// import moment from 'moment';
import querystring from 'query-string';
import styled from 'styled-components';

import { useSelector } from 'react-redux';
import heuteGesternDatum from './heuteGesternDatum';
import NewsListStyle from '../styles/NewsList';
// import PageTitle from './PageTitle';

const NewsContainer = styled.li`
  border-bottom-color: ${(props) => props.theme.liNewsListBorderBottom};
  background-color: ${(props) => props.theme.liNewsItemBgL};
  @media (min-width: 768px) : {
    background-color: ${(props) => props.theme.liNewsItemBgL};
  }
`;

const NewsDateItem = styled.span`
  color: ${(props) => props.theme.newsItemDateS};
  @media (min-width: 768px) : {
    color: ${(props) => props.theme.newsItemDateL};
  }
`;

const Tag = styled.button`
  margin: 3px 6px 3px 0;
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  border: none;
  color: #fff;
  &:hover {
    color: #fff;
  }
  &:focus {
    border: none;
    color: #fff;
    outline: none;
  }
  @media (max-width: 768px) {
    margin: 3px 4px 3px 0;
    padding: 3px 4px;
  }
`;

function strip(html) {
  const tmp = document.createElement('DIV');
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || '';
}

function getTruncatedProp(value, length) {
  if (typeof value === 'string' && value.length > length && length > 0) {
    const spaceIndex = value.lastIndexOf(' ', length - 1);
    const cropIndex = spaceIndex > 1 ? spaceIndex : length - 1;
    return `${value.substring(0, cropIndex)}...`;
  }
  return value;
}

function RenderNewsListItem({ item, theme, db, newsId }) {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const to = useMemo(
    () => ({
      pathname: `/object/A11/${item.guid}/${db}`,
      search: querystring.stringify({ prev: pathname }),
    }),
    [db, item.guid, pathname]
  );
  return (
    <NewsContainer className="newsItem clearfix">
      <Link key={`newsItem-${item.id}`} to={to}>
        <span className="col-xs-3" style={{ padding: 0 }}>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            {item.imagePreview ? (
              <img
                src={item.imagePreview}
                className="img-responsive"
                style={{ width: '100%' }}
                alt=""
              />
            ) : null}
          </div>
        </span>
        <div className="col-xs-9" style={{ padding: '5px 15px' }}>
          <div className="newsItem-top">
            <span
              className="newsItem-category"
              style={{
                color: theme.newsItemCategory,
                borderBottomColor: theme.newsItemTitleBorderBottom,
              }}
            >
              <NewsDateItem className="newsItem-date">
                {heuteGesternDatum(item.timestamp, 'MM/DD/YYYY HH:mm:ss')}
              </NewsDateItem>
            </span>
          </div>
          <br />
          <div className="newsItem-content">
            <span className="newsItem-title hidden-xs" style={{ color: theme.newsItemTitle }}>
              {item.title}
            </span>
            <span className="newsItem-title visible-xs" style={{ fontWeight: 'normal' }}>
              {item.title}
            </span>
            <div className="hidden-xs">{getTruncatedProp(strip(item.text), 220)}</div>
          </div>
          <div>
            {item.tags !== ''
              ? item.tags.map(({ tag, color }) => (
                <Tag
                  key={tag}
                  $backgroundColor={color}
                  type="button"
                  className="btn btn-sm"
                  onClick={(e) => {
                    e.preventDefault();
                    const url = `/news/${newsId}/tag/${tag}`;
                    navigate(url);
                  }}
                >
                  {tag}
                </Tag>
              ))
              : null}
          </div>
        </div>
        <div className="clearfix newsItem-bottom" style={{ borderBottomColor: '#f2f4f7' }} />
      </Link>
    </NewsContainer>
  );
}

export default function NewsList({ newsId, newsItems, db }) {
  const theme = useSelector((state) => state.config.theme);

  return (
    <NewsListStyle>
      <ul className="NewsList" style={{ backgroundColor: theme.NewsListBg }}>
        {/* <PageTitle>SafetyNews</PageTitle> */}
        {newsItems.map((item, index) => (
          <RenderNewsListItem key={index} item={item} theme={theme} db={db} newsId={newsId} />
        ))}
      </ul>
    </NewsListStyle>
  );
}
