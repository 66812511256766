import moment from 'moment';
import trans from './trans';

export default function heuteGesternDatum(date, currFormat) {
  const newsDate = moment(date, currFormat);
  const today = moment();
  if (today.isSame(newsDate, 'day')) {
    return trans('TODAY');
  }
  const yesterday = moment().subtract(1, 'days');
  if (yesterday.isSame(newsDate, 'day')) {
    return trans('YESTERDAY');
  }
  if (trans.lang === 'de') {
    return newsDate.locale('de').format('DD.MM.YYYY');
  }
  return newsDate.locale('en').format('ll');
}
