export function mapResponseData(payload) {
  return (payload.data || []).map((item, idx) => ({
    id: payload.meta[idx].recordID,
    guid: item.a001t_GUID,
    url: item.f002t_Link_URL,
    language: item.f004t_Sprache,
    width: parseInt(item.n002n_Breite, 10),
    height: parseInt(item.n003n_Hoehe, 10),
    format: item.n004t_Format,
    image: item.f012t_URL,
    location: item.f006t_Location,
    counter: item.f008n_Counter,
    premium: item.f007b_Premium === '1',
  }));
}

export function getHead(banners) {
  return banners.filter((b) => b.location === 'head');
}

export function getTop(banners) {
  return banners.filter((b) => b.location === 'top');
}

export function getSideBanner(banners) {
  return banners.filter((b) => b.location === 'side');
}

export function getMobileBanner(banners) {
  return banners.filter((b) => b.location === 'mobile');
}

export function getBackBanner(banners) {
  return banners.filter((b) => b.location === 'back');
}
