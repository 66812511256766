import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import trans from '../components/trans';
import BackButton from '../components/BackButton';
import { Helmet } from 'react-helmet';

const Container = styled.div`
  background-color: #f4f5f7;
`;

const Block = styled.div`
  max-width: 1280px;
  margin: 0 auto;
  padding: 20px 0px;
  @media (max-width: 1280px) {
    padding: 20px 15px;
  }
`;

export default function LegalNotice() {
  const navigate = useNavigate()
  const rechtlichhtml = useSelector(state => state.footer && state.footer.data && state.footer.data.f387t_Web_Rechtlich)
  const rechtlichtitel = useSelector(state => state.footer && state.footer.data && state.footer.data.f386t_Web_Rechtlich_Titel)
  if (!rechtlichhtml) {
    return null;
  }
  return (
    <Container>
      <Block>
        <BackButton onClick={() => navigate(-1)}>« {trans('BACK_TO_OVERVIEW')}</BackButton>
        <div dangerouslySetInnerHTML={{ __html: rechtlichhtml }} />
      </Block>
      <Helmet title={rechtlichtitel} />
    </Container>
  );
}