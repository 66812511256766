import React, { useState, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import querystring from 'query-string';
import styled from 'styled-components';

import backendQuery from '../actions/backendQuery';

import { translatedTextForObject } from './trans';
import Section from './Section';
import { useLocation } from 'react-router-dom';

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
const Box = styled.div`
  // margin-right: 15px;
  padding: 0 10px;
`;

export default function SupporterBox({ url, db, title }) {
  // const config = useSelector(state => state.config)
  // const { showSupporterBox, supporterboxURL, theme, supporterboxDB, supporterboxText } = config
  const [supporterBoxData, setSupporterBoxData] = useState([]);

  const { pathname, search } = useLocation()
  const linkToSearchPart = useMemo(() => querystring.stringify({
    prev: pathname + search,
    type: 'A11',
    field: 'f036t_Gueltigkeitsdatum_ISO',
    _k: 'xte',
    desc: true,
  }), [pathname, search])

  useEffect(() => {
    backendQuery({ url: url, RFMmax: 0 }).then((response) => setSupporterBoxData(response.data));
  }, [url]);

  if (!supporterBoxData) { return null; }
  return (
    <Box>
      <Section $margin="10px 0">{translatedTextForObject(title)}</Section>
      <Container>
        {supporterBoxData.map((company) => (
          <Link
            style={{ width: '50%', minWidth: 100 }}
            className="link"
            key={company.a001t_GUID}
            to={{
              pathname: `/object/B02/${company.a001t_GUID}/${db}`,
              search: linkToSearchPart,
            }}
          >
            {company.f027t_Firma_Sponsoring_Box}
          </Link>
        ))}
      </Container>
    </Box>
  );
}
