import { createAction, handleActions } from 'redux-actions';


function mergeAndSortArrays(array1, array2 = []) {
  const allItems = array1.concat(array2);
  return allItems
    .filter((item, pos) => allItems.indexOf(item) === pos)
    .sort((a, b) => {
      if (a.toLowerCase() < b.toLowerCase()) {
        return -1;
      }
      return 1;
    });
}

function filterList(selectedFilters, element) {
  const noFilterIsSelected = selectedFilters.length === 0;
  const elementMatchFilter = selectedFilters.indexOf(element.f025b_Aktuell) !== -1;
  const showElement = noFilterIsSelected || elementMatchFilter;
  return showElement;
}

function docMatchesDoctype(res, selected_doctypes) {
  return selected_doctypes.length === 0 || selected_doctypes.indexOf(res.element.n004t_Typ) !== -1;
}

function docMatchesAuthor(res, selected_authors) {
  return (
    selected_authors.length === 0 ||
    selected_authors.indexOf(res.element.n020t_Anzeige_Suche_Autor) !== -1
  );
}

function performFilter(newState) {
  const results = newState.allResults[newState.key];
  const filteredResults =
    results && results.data.length > 0
      ? results.data
        .filter((res) => filterList(newState.selected_states, res.element))
        .filter((res) =>
          newState.selected_doctypes.length
            ? docMatchesDoctype(res, newState.selected_doctypes)
            : res
        )
        .filter((res) =>
          newState.selected_authors.length
            ? docMatchesAuthor(res, newState.selected_authors)
            : res
        )
        .filter((v) => v)
      : [];
  return {
    query: newState.query,
    actual: newState.actual,
    selected_doctypes: newState.selected_doctypes,
    selected_authors: newState.selected_authors,
    backFromDetailView: newState.backFromDetailView,
    allResults: newState.allResults,
    selected_states: newState.selected_states,
    key: newState.key,
    type: newState.type,
    loaded: true,

    doctypes: mergeAndSortArrays(newState.doctypes),
    authors: mergeAndSortArrays(newState.authors),
    filteredResults,
    count: filteredResults.length,
  };
}


const SEARCH_FROM_ID_REQUEST = 'SEARCH_FROM_ID_REQUEST';
const BACK_FROM_DETAIL_VIEW_SET = 'BACK_FROM_DETAIL_VIEW_SET';
const BACK_FROM_DETAIL_VIEW_UNSET = 'BACK_FROM_DETAIL_VIEW_UNSET';
const CHANGE_ACTUAL = 'CHANGE_ACTUAL';
const ADD_DOCTYPE = 'ADD_DOCTYPE';
const REMOVE_DOCTYPE = 'REMOVE_DOCTYPE';
const ADD_AUTHOR = 'ADD_AUTHOR';
const REMOVE_AUTHOR = 'REMOVE_AUTHOR';
const SEARCH_RESET = 'SEARCH_RESET';
const SEARCH_REQUEST = 'SEARCH_REQUEST';
const SEARCH_FROM_ID_CACHE = 'SEARCH_FROM_ID_CACHE';
const ADD_DOCSTATE = 'ADD_DOCSTATE';
const REMOVE_DOCSTATE = 'REMOVE_DOCSTATE';
const RELATED_ARTICLES = 'RELATED_ARTICLES';
const SET_QUERYSTRING = 'SET_QUERYSTRING';
const RESET_FILTER = 'RESET_FILTER';
// const SET_DOCTYPES = 'SET_DOCTYPES';

export const setBackFromDetail = createAction(BACK_FROM_DETAIL_VIEW_SET);
export const unsetBackFromDetail = createAction(BACK_FROM_DETAIL_VIEW_UNSET);
export const resetSearch = createAction(SEARCH_RESET);
export const setQuerystring = createAction(SET_QUERYSTRING);
export const doSearch = createAction(SEARCH_REQUEST);
export const getRelatedArticles = createAction(RELATED_ARTICLES);
export const doSearchFromIdFromCache = createAction(SEARCH_FROM_ID_CACHE);
export const addDoctype = createAction(ADD_DOCTYPE);
export const removeDoctype = createAction(REMOVE_DOCTYPE);
export const addAuthor = createAction(ADD_AUTHOR);
export const removeAuthor = createAction(REMOVE_AUTHOR);
export const addDocState = createAction(ADD_DOCSTATE);
export const removeDocState = createAction(REMOVE_DOCSTATE);
export const changeActual = createAction(CHANGE_ACTUAL);
export const resetFilters = createAction(RESET_FILTER);

const emptySearch = {
  query: null,
  actual: true,
  doctypes: [],
  selected_authors: [],
  authors: [],
  selected_doctypes: [],
  backFromDetailView: false,
  allResults: {},
  loaded: false,
  selected_states: [],
  key: null,
  type: null,
};

function remove_and_filter(property_name) {
  return (state, { payload }) => {
    const idx = state[property_name] && state[property_name].indexOf(payload);
    return idx === -1
      ? state
      : performFilter({
        ...state,
        [property_name]: state[property_name].filter((_, index) => index !== idx),
      });
  };
}

function add_and_filter(property_name) {
  return (state, { payload }) => {
    const idx = state[property_name] && state[property_name].indexOf(payload);
    return idx !== -1
      ? state
      : performFilter({ ...state, [property_name]: [...state[property_name], payload] });
  };
}

export const search = handleActions(
  {
    [BACK_FROM_DETAIL_VIEW_SET]: (state) => ({ ...state, backFromDetailView: true }),
    [BACK_FROM_DETAIL_VIEW_UNSET]: (state) => ({ ...state, backFromDetailView: false }),
    [SET_QUERYSTRING]: (state, action) => ({ ...state, key: action.payload }),

    [SEARCH_RESET]: (state, { payload }) => ({
      ...emptySearch,
      query: payload ? payload.query : null,
      allResults: state.allResults, // Why use allResults???
    }),

    [RELATED_ARTICLES]: (state, { payload: { parsedResponse, query } }) =>
      performFilter({
        ...emptySearch,
        ...parsedResponse,
        key: query,
        allResults: {
          [query]: {
            data: parsedResponse.responseResults,
            loaded: true,
          },
          type: RELATED_ARTICLES,
        },
        backFromDetailView: state.backFromDetailView,
        query,
        // query: state.query,
      }),
    [SEARCH_REQUEST]: (
      state,
      {
        payload: {
          parsedResponse,
          query,
          selected_doctypes = [],
          selected_authors = [],
          selected_states = [],
        },
      }
    ) => {
      return performFilter({
        ...state,
        ...parsedResponse,
        query,
        authors: mergeAndSortArrays(parsedResponse.authors, [
          ...state.authors,
          ...selected_authors,
        ]),
        selected_authors,
        doctypes: mergeAndSortArrays(parsedResponse.doctypes, [
          ...state.doctypes,
          ...selected_doctypes,
        ]),
        selected_states,
        selected_doctypes,
        allResults: {
          ...(query !== state.query && state.type === SEARCH_REQUEST ? state.allResults : {}),
          query,
          [query]: {
            data: parsedResponse.responseResults,
            loaded: true,
          },
        },
        type: SEARCH_REQUEST,
      });
    },
    [SEARCH_FROM_ID_CACHE]: (
      state,
      {
        payload: {
          parsedResponse,
          query,
          selected_doctypes = [],
          selected_states = [],
          selected_authors = [],
        },
      }
    ) =>
      performFilter({
        ...state,
        ...parsedResponse,
        authors: mergeAndSortArrays(parsedResponse.authors, state.authors),
        doctypes: mergeAndSortArrays(parsedResponse.doctypes, [...selected_doctypes]),
        selected_states,
        selected_authors,
        selected_doctypes,
        allResults: {
          ...state.allResults,
          [query]: {
            data: parsedResponse.responseResults,
            loaded: true,
          },
          type: SEARCH_FROM_ID_REQUEST,
        },
        query: null,
      }),
    [ADD_AUTHOR]: add_and_filter('selected_authors'),
    [REMOVE_AUTHOR]: remove_and_filter('selected_authors'),
    [ADD_DOCTYPE]: add_and_filter('selected_doctypes'),
    [REMOVE_DOCTYPE]: remove_and_filter('selected_doctypes'),
    [ADD_DOCSTATE]: add_and_filter('selected_states'),
    [REMOVE_DOCSTATE]: remove_and_filter('selected_states'),
    [RESET_FILTER]: (state) =>
      performFilter({
        ...state,
        selected_states: [],
        selected_doctypes: [],
        selected_authors: [],
      }),
    [CHANGE_ACTUAL]: (state, { payload: selected }) =>
      performFilter({ ...state, actual: selected }),
  },
  emptySearch
);
