import React, { useMemo, useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroller';
import stringFormat from 'string-format';
import BannerComponent from '../components/BannerComponent';
import NewsList from '../components/NewsList';
import trans, { translatedTextForObject } from '../components/trans';
import { getNewsList, getMoreNews } from '../actions/newsList';
import backendQuery from '../actions/backendQuery';
import { useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';

// function usePrevious(value) {
//   const ref = useRef();
//   useEffect(() => {
//     ref.current = value;
//   });
//   return ref.current;
// }

export default function NewsListPage() {
  const allNews = useSelector(state => state.newsList && state.newsList.allNews)
  const newsListConfig = useSelector(state => state.config && state.config.newsList)

  const dispatch = useDispatch();
  const navigate = useNavigate()

  const { newsId } = useParams()

  const { tabs,
    // defaultTab
  } = newsListConfig || {}

  const tab = useMemo(() => tabs && tabs.find((t) => t.name.de === newsId), [tabs, newsId]);

  const [requestfailedError, requestfailedErrorSet] = useState()

  useEffect(() => {
    if (!tabs) {
      return
    }
    if (!tab) {
      const firstTab = tabs[0];
      navigate(`/news/${firstTab.name.de}/`);
    }
  }, [navigate, tab, tabs]);

  // const prevCount = usePrevious(props.match.params.newsId);

  const newsData = useMemo(() => {
    if (!allNews) {
      return null;
    }
    if (!allNews[newsId]) {
      return null;
    }
    return allNews[newsId];
  }, [allNews, newsId]);

  useEffect(() => {
    if (!tab) {
      return;
    }
    requestfailedErrorSet(null)
    const url = stringFormat(tab.url, tab.db);
    backendQuery({
      url,
      RFMscript: tab.RFMscript,
      RFMmax: 10,
    }).then((resp) => {
      dispatch(getNewsList({ ...resp, newsId }));
    }, err => {
      console.log('err', err)
      requestfailedErrorSet(`Error querying url "${url}": ${err.errorMessage || err}`)
    });
  }, [newsId, tab, dispatch]);

  const pageTitle = useMemo(() => {
    if (!tab) {
      return
    }
    return translatedTextForObject(tab.name)
  }, [tab])

  if (requestfailedError) {
    return <div style={{ padding: '20px', background: '#fff' }}>{requestfailedError}</div>
  }
  if (!newsData) {
    return null;
  }
  return <><BannerComponent>
    <NewsListPageContent newsId={newsId} newsData={newsData} tab={tab} /><Helmet title={pageTitle} /></BannerComponent></>
}

function NewsListPageContent({ newsId, newsData, tab }) {
  const dispatch = useDispatch();

  const loadMore = useCallback(async () => {
    let resp
    const url = `${tab.baseURL}${newsData.hasMore}`
    try {
      resp = await backendQuery({ url })
    } catch (err) {
      console.log('Error fetching url', url)
    }
    if (resp) {
      dispatch(getMoreNews({ ...resp, newsId }))
    }
  }, [dispatch, newsData.hasMore, newsId, tab.baseURL]);

  const hasMore = useMemo(() => (newsData.hasMore ? true : false), [newsData]);

  const loader = useMemo(
    () => (
      <div key={'key'} className="ma2 b tc">
        {trans('loader.loading')}
      </div>
    ),
    []
  );

  return (
    <InfiniteScroll pageStart={0} loadMore={loadMore} hasMore={hasMore} loader={loader}>
      <NewsList newsId={newsId} newsItems={newsData.data} db={tab.db} />
    </InfiniteScroll>
  );
}
