import { createAction, handleActions } from 'redux-actions';

const DO_CACHE_SEARCH = 'DO_CACHE_SEARCH';

export const doCacheSearch = createAction(DO_CACHE_SEARCH);

export const searchCache = handleActions(
  {
    [DO_CACHE_SEARCH]: (state, { payload: { id, value } }) => ({ ...state, [id]: value }),
  },
  {}
);
