import styled from 'styled-components';

const StyledCheckbox = styled.input.attrs({
  type: 'checkbox',
})`
  height: 16px;
  width: 16px;
  :checked {
    -webkit-appearance: none;
    -moz-appearance: none;
    background-color: ${(props) => props.theme.primary};
    height: 16px;
    width: 16px;
    position: relative;
    color: white;
  }
  :checked::before {
    content: '✓';
    position: absolute;
    font-size: 16px;
    line-height: 1;
    top: 0;
    left: 0;
    height: 14px;
    width: 14px;
    left: 50%;
    margin-left: -7px;
    top: 50%;
    margin-top: -7px;
    color: white;
    visibility: visible;
  }
`;

export default StyledCheckbox;
