import React, { useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import trans from './trans';
import BannerComponentStyle from '../styles/BannerComponent';
import BannerStoreContext from '../context/BannerStore';
import SupporterBox from './SupporterBox';
import EntriesList from './EntriesList';
import LastEntriesList from './LastEntriesList';
import { useLocation } from 'react-router';

function RenderOneNormalBanner({ widgetConf, pathname }) {
  if (widgetConf.type === 'supporterBoxWidget') {
    return <SupporterBox {...widgetConf} />;
  }
  if (widgetConf.type === 'entriesList') {
    return <EntriesList {...widgetConf} />;
  }
  if (widgetConf.type === 'lastEntriesList') {
    return <LastEntriesList pathname={pathname} {...widgetConf} />;
  }
  return null;
}

function RenderCustomSecondColumnWidgets({ position, customSecondColumnWidgets, pathname }) {
  const filteredBanner = useMemo(
    () => customSecondColumnWidgets.filter((p) => p.position === position),
    [position, customSecondColumnWidgets]
  );
  return filteredBanner.map((widgetConf, index) => (
    <RenderOneNormalBanner widgetConf={widgetConf} key={index} pathname={pathname} />
  ));
}

const PageContainer = styled.div`
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;
  // margin-top: 65px;

  display: flex;
  justify-content: space-between;
  margin-top: ${(props) => props.$marginTop};
  @media (max-width: 768px) {
    margin-top: 0px;
  }
`;

const LeftAdvertText = styled.span`
  color: rgb(110, 120, 130);
  position: absolute;
  bottom: 15px;
  left: -10px;
  background: transparent !important;
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  transform: rotate(-90deg);
`;

const TopAdvertText = styled.span`
  color: rgb(110, 120, 130);
  position: absolute;
  top: -20px;
  left: 0;
  background: transparent !important;
`;

const AdvertText = ({ isLeft, children }) =>
  isLeft ? <LeftAdvertText>{children}</LeftAdvertText> : <TopAdvertText>{children}</TopAdvertText>;

const NewsColumn = styled.div`
  order: 1;
  flex: 2;
  margin-right: 15px;
  @media (max-width: 1250px) {
    flex: 3;
    // padding: 10px;
  }

  @media (max-width: 960px) {
    padding-top: 0;
    order: 2;
    margin-right: 0px;
    padding: 0px;
  }
`;
const BannerWrapper = styled(BannerComponentStyle)`
  background-image: url(${(props) => props.back && props.back.image});
  position: ${(props) => props.back && 'absolute'};
  width: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
  background-size: cover;
  background-color: none;

  @media (max-width: 768px) {
    background-image: none;
    position: unset;
    background-color: white;
  }
`;

const BannerComponentTop = styled.div`
  position: absolute;
  top: 0;
  padding-right: 20px;
  @media (max-width: 960px) {
    padding-right: 0px;
  }
  @media (max-width: 768px) {
    display: none;
  }
`;

const CustomColumsColum = styled.div`
  order: 2;
  display: flex;
  justify-content: space-between;
  background-color: #f4f5f7 !important;
  @media (max-width: 1250px) {
    flex-direction: column;
    margin-top: 0px;
  }
`;

const BannerContainer = styled.div`
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;
  flex: 1;
  & > .BannerComponent-Banner {
    padding-top: 10px;
  }
`;

const WidgetContainer = styled.div`
  flex: 1;
  padding: '0 15px 15px 15px';
  @media (max-width: 1250px) {
    order: 2;
  }
`;

const ConfigColumn = styled.div`
  order: 2;
  flex: 1;
  padding-right: 20px;
  @media (max-width: 1250px) {
    flex: 1;
    min-width: 290px;
  }
  @media (max-width: 960px) {
    order: 1;
    padding: 10px;
    justify-content: space-between;
    flex-direction: column;
    display: none;
  }
`;
const BannerHolder = styled.div`
  position: relative;
  ${({ $isTop }) => ($isTop ? 'padding: 0 25px;' : null)}
`;

const BannerWrap = styled.div`
  display: flex;
  justify-content: center;
`;

function RenderOneBanner({
  url,
  width,
  height,
  image,
  style,
  bannerAnzeigeTextHide,
  isLeft,
  isTop,
}) {
  return (
    <BannerWrap key={url} className="BannerComponent-Banner">
      <BannerHolder $isTop={isTop}>
        <a href={url} target="_blank" rel="noopener noreferrer">
          <img alt="" width={width} height={height} src={image} />
        </a>
        {!bannerAnzeigeTextHide ? (
          <AdvertText isLeft={isLeft} style={style} className="advertLabel">
            {trans('banner.advert')}
          </AdvertText>
        ) : null}
      </BannerHolder>
    </BannerWrap>
  );
}

function RenderBanners({ banners, style, bannerAnzeigeTextHide, isLeft, isTop }) {
  return banners.map((banner, index) => (
    <RenderOneBanner
      key={index}
      width={banner.width}
      height={banner.height}
      image={banner.image}
      url={banner.url}
      style={style}
      bannerAnzeigeTextHide={bannerAnzeigeTextHide}
      isLeft={isLeft}
      isTop={isTop}
    />
  ));
}

function RenderHeadBanner({
  url,
  width, // height,
  image,
}) {
  return (
    <BannerComponentTop className="hidden-xs">
      <BannerWrap className="BannerComponent-Banner">
        <BannerHolder>
          <a href={url} target="_blank" rel="noopener noreferrer">
            <img
              alt=""
              width="100%"
              // height={height} <-- don't set the height because the image might be scaled
              style={{ maxWidth: width }}
              src={image}
            />
          </a>
        </BannerHolder>
      </BannerWrap>
    </BannerComponentTop>
  );
}

export default function BannerComponent({
  children,
}) {
  const { pathname } = useLocation()

  const theme = useSelector(state => state.config.theme)
  const bannerAnzeigeTextHide = useSelector(state => state.config.bannerAnzeigeTextHide)
  const customSecondColumnWidgets = useSelector(state => state.config.customSecondColumnWidgets)

  const { top, side, mobile, back, head, isTop, closedBanner, setCloseBanner } =
    useContext(BannerStoreContext);

  const style = useMemo(
    () => ({ color: theme.advertLabel, background: theme.advertLabelBg }),
    [theme]
  );

  const pageShowsBackground = useMemo(() => back && back[0], [back]);
  const mainContentMarginTop = useMemo(
    () => (pageShowsBackground ? '170px' : null),
    [pageShowsBackground]
  );
  const theHeadBanner = useMemo(() => (head ? head[0] : null), [head]);

  return (
    <>
      {/* TODO: Maybe redirect to somewhere on click on the background img to some page */}
      <BannerWrapper back={pageShowsBackground} className="bg">
        <div></div>
        <PageContainer className="BannerComponent" $marginTop={mainContentMarginTop}>
          <NewsColumn style={{ backgroundColor: theme.NewsListBg }}>
            <div className="BannerComponent-Top hidden-xs">
              {top ? (
                <RenderBanners
                  banners={top}
                  style={style}
                  bannerAnzeigeTextHide={bannerAnzeigeTextHide}
                  isLeft
                  isTop={isTop}
                />
              ) : null}
            </div>
            {theHeadBanner ? (
              <RenderHeadBanner
                url={theHeadBanner.url}
                width={theHeadBanner.width}
                image={theHeadBanner.image}
              />
            ) : null}

            <div className="visible-xs">
              {!mobile || !mobile.length || closedBanner ? null : (
                <div
                  style={{
                    color: theme.BannerComponentOntop,
                    backgroundColor: theme.BannerComponentOntopBg,
                  }}
                  className="BannerComponent-Top BannerComponent-Ontop visible-xs"
                >
                  <RenderBanners
                    banners={mobile}
                    style={style}
                    bannerAnzeigeTextHide={bannerAnzeigeTextHide}
                  />
                  <span
                    className="BannerComponent-Close"
                    style={{
                      cursor: 'pointer',
                      padding: '0px',
                      color: theme.BannerComponentClose,
                    }}
                    onClick={() => setCloseBanner(true)}
                  >
                    {trans('banner.close')}
                  </span>
                </div>
              )}
            </div>
            <br className="visible-md visible-lg" />
            <div className="BannerComponent-Content">{children}</div>
          </NewsColumn>
          <ConfigColumn back={back && back[0]}>
            <div>
              <RenderCustomSecondColumnWidgets
                position="top"
                customSecondColumnWidgets={customSecondColumnWidgets}
                pathname={pathname}
              />
            </div>
            <CustomColumsColum>
              <WidgetContainer>
                <RenderCustomSecondColumnWidgets
                  position="left"
                  customSecondColumnWidgets={customSecondColumnWidgets}
                  pathname={pathname}
                />
              </WidgetContainer>
              <BannerContainer className="BannerComponent-Right">
                <RenderBanners
                  banners={side}
                  style={style}
                  bannerAnzeigeTextHide={bannerAnzeigeTextHide}
                />
              </BannerContainer>
            </CustomColumsColum>
          </ConfigColumn>
        </PageContainer>
      </BannerWrapper>
    </>
  );
}
