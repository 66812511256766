import styled from 'styled-components';

const MainStyle = styled.div`
  /* Stiziles */

  html,
  body {
  }

  /* main */
  .main {
    font-family: 'Roboto Confensed', sans-serif;
  }

  /* transitions */

  .fade-enter {
    opacity: 0.01;
    transition: opacity 0.5s ease-in;
  }

  .fade-enter.fade-enter-active {
    opacity: 1;
  }

  .fade-leave {
    opacity: 1;
    transition: opacity 0.5s ease-in;
  }

  .fade-leave.fade-leave-active {
    opacity: 0.01;
  }

  .navbar {
    margin-bottom: 0px !important;
  }

  .navbar-default .navbar-nav > li > a {
    font-weight: 500;
  }

  @media (min-width: 768px) {
    .navbar-header {
      float: none;
    }
    .navbar-collapse.in {
      overflow-y: auto !important;
      display: block !important;
    }
    .navbar-collapse.collapse.in {
      display: block !important;
    }
    .navbar-collapse.collapse {
      display: none !important;
    }
    .navbar-collapse {
      display: none !important;
      overflow-x: visible !important;
      padding-right: 15px;
      padding-left: 15px;
      border-top: 1px solid transparent;
      box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
      -webkit-overflow-scrolling: touch;
    }
    .navbar-toggle {
      display: initial;
      position: relative;
      float: right;
      margin-right: 15px;
      padding: 9px 10px;
      margin-top: 8px;
      margin-bottom: 8px;
      background-color: transparent;
      background-image: none;
      border: 1px solid transparent;
      border-radius: 4px;
    }
    .navbar-nav > li {
      float: none;
    }
    .navbar-nav {
      float: none;
    }
  }
`;
export default MainStyle;
