import React from 'react';
import { createRoot } from 'react-dom/client';

import '@formatjs/intl-pluralrules/polyfill';
import '@formatjs/intl-pluralrules/locale-data/de'; // Add locale data for de
import '@formatjs/intl-pluralrules/locale-data/en';
import '@formatjs/intl-relativetimeformat/polyfill';
import '@formatjs/intl-relativetimeformat/locale-data/de'; // Add locale data for de
import '@formatjs/intl-relativetimeformat/locale-data/en';

import 'typeface-roboto';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'tachyons/css/tachyons.css';

import App from './routes';

// import 'core-js/es/index';
// import 'core-js';
import 'core-js/features/set';

// Failed to load resource: net::ERR_BLOCKED_BY_CLIENT <-- Adblocker

if (process.env.NODE_ENV === 'development') {
  const whyDidYouRender = require('@welldone-software/why-did-you-render');
  whyDidYouRender(React, {
    trackAllPureComponents: true,
  });
}

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<App />);
