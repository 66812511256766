import React, { useMemo } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import querystring from 'query-string';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import trans from './trans';
import Section from './Section';

const COMPANY_COLUMN_WIDTH = '21rem';
const DATE_COLUMN_WIDTH = '10rem';

const COLUMNS_CONFIGS = {
  A11: {
    col0: { property: 'n005t_Bezeichnung', label: 'searchlist.A11' },
    col1: {
      property: 'n010t_Anz_Firma',
      label: 'objectlistheader.authorsource',
      className: 'hidden-xs',
      width: COMPANY_COLUMN_WIDTH,
    },
    col2: {
      property: 'f036t_Gueltigkeitsdatum_ISO',
      label: 'objectlistheader.publicationdate',
      type: 'date',
      className: 'hidden-xs tr',
      width: DATE_COLUMN_WIDTH,
    },
  },
  B04: {
    col0: { property: 'n005t_Bezeichnung', label: 'searchlist.B04' },
    col1: {
      property: 'n010t_Anz_Firma',
      label: 'objectlistheader.author',
      className: 'hidden-xs',
      width: COMPANY_COLUMN_WIDTH,
    },
    col2: {
      property: 'f036t_Gueltigkeitsdatum_ISO',
      label: 'objectlistheader.validfrom',
      type: 'date',
      className: 'hidden-xs tr',
      width: DATE_COLUMN_WIDTH,
    },
  },
  B14: {
    col0: { property: 'n005t_Bezeichnung', label: 'searchlist.B14' },
    col1: {
      property: 'n010t_Anz_Firma',
      label: 'objectlistheader.host',
      className: 'hidden-xs',
      width: COMPANY_COLUMN_WIDTH,
    },
    col2: {
      property: 'f036t_Gueltigkeitsdatum_ISO',
      label: 'objectlistheader.eventdate',
      type: 'date',
      className: 'hidden-xs tr',
      width: DATE_COLUMN_WIDTH,
    },
  },
  B01: {
    col0: { property: 'n005t_Bezeichnung', label: 'searchlist.B01' },
    col2: {
      property: 'f036t_Gueltigkeitsdatum_ISO',
      label: 'objectlistheader.validfrom',
      type: 'date',
      className: 'hidden-xs tr',
      width: DATE_COLUMN_WIDTH,
    },
  },
  B11: {
    col0: { property: 'n005t_Bezeichnung', label: 'searchlist.B11' },
    col1: {
      property: 'n010t_Anz_Firma',
      label: 'objectlistheader.manufacturer',
      className: 'hidden-xs',
      width: COMPANY_COLUMN_WIDTH,
    },
  },
  B02: {
    col0: { property: 'n005t_Bezeichnung', label: 'searchlist.B02' },
  },
  B10: {
    col0: { property: 'n005t_Bezeichnung', label: 'searchlist.B10' },
  },
  B06: {
    col0: { property: 'n005t_Bezeichnung', label: 'searchlist.B06' },
    col1: {
      property: 'n010t_Anz_Firma',
      label: 'objectlistheader.manufacturer',
      className: 'hidden-xs',
      width: COMPANY_COLUMN_WIDTH,
    },
  },
  B07: {
    col0: { property: 'n005t_Bezeichnung', label: 'searchlist.B07' },
  },
};

// compare function for the sort call in renderItems
// compareFunction: A function that defines an alternative sort order.
// The function should return a negative, zero, or positive value, depending on the arguments

function compareItems(first, second, field, desc, sortType, currType) {
  if (sortType !== currType) {
    field = 'n005t_Bezeichnung';
    desc = false;
  }
  if (!first.element || !first.element[field] || !first.element[field].toLowerCase) {
    return desc ? 1 : -1;
  }
  if (!second.element || !second.element[field] || !second.element[field].toLowerCase) {
    return desc ? -1 : 1;
  }
  const firstValue = first.element[field].toLowerCase();
  const secondValue = second.element[field].toLowerCase();

  if (firstValue === secondValue) {
    return 0;
  }
  if (firstValue < secondValue) {
    return desc ? 1 : -1;
  }
  if (firstValue > secondValue) {
    return desc ? -1 : 1;
  }
  return 0;
};

function RenderObjectListItems({
  type,
  locatioSearchParsedType,
  status,
  previousUrl,
  columnsConfig,
}) {
  const sortedLst = useMemo(
    () =>
      type.list.sort((a, b) =>
        compareItems(a, b, type.searchField, type.desc, locatioSearchParsedType, type.type)
      ),
    [locatioSearchParsedType, type.desc, type.list, type.searchField, type.type]
  );

  return sortedLst.map((item, key) => (
    <ObjectListItem
      key={`object-list-item-${item.element.id + key}`}
      item={item.element}
      actualState={status}
      db={item.db}
      previousUrl={previousUrl}
      columnsConfig={columnsConfig}
    />
  ));
}

export default function SearchObjectListTableContent({ status, localResults }) {
  const location = useLocation();
  const locatioSearchParsedType = useMemo(() => {
    const query = querystring.parse(location.search);
    return query.type;
  }, [location.search]);
  const previousUrl = useMemo(
    () => `${location.pathname}${location.search}`,
    [location.pathname, location.search]
  );
  return localResults.map((type) => {
    const columnsConfig = COLUMNS_CONFIGS[type.type];
    return (
      <React.Fragment key={type.type}>
        <ObjectListHeader
          type={type.type}
          location={location}
          columnsConfig={columnsConfig}
        />
        <RenderObjectListItems
          type={type}
          locatioSearchParsedType={locatioSearchParsedType}
          status={status}
          previousUrl={previousUrl}
          columnsConfig={columnsConfig}
        />
      </React.Fragment>
    );
  });
}


function SortButton({ query, field, type }) {
  if (query.type === type && query.field === field) {
    if (query.desc && query.type === type && query.field === field) {
      return (
        <span
          style={{ fontSize: 12 }}
          className="glyphicon glyphicon-arrow-down"
          aria-hidden="true"
        />
      );
    }
    return (
      <span style={{ fontSize: 12 }} className="glyphicon glyphicon-arrow-up" aria-hidden="true" />
    );
  }
  if (query.type === undefined && field === 'n005t_Bezeichnung') {
    return (
      <div>
        <span
          style={{ fontSize: 12 }}
          className="glyphicon glyphicon-arrow-down"
          aria-hidden="true"
        />
        <span
          style={{ fontSize: 12 }}
          className="glyphicon glyphicon-arrow-up"
          aria-hidden="true"
        />
      </div>
    );
  }
  return null;
};

const getNewQuery = (query, type, field, key) => {
  const newQuery = { ...query, type, field, _k: key };
  delete newQuery.desc;
  if (type === query.type && !query.desc) {
    newQuery.desc = true;
  }
  if (query.type === undefined && field === 'n005t_Bezeichnung') {
    newQuery.desc = true;
  }
  return querystring.stringify(newQuery);
};

function ObjectListHeader({
  type,
  location: { search, pathname, key },
  columnsConfig,
}) {
  const navigate = useNavigate();
  const query = querystring.parse(search);
  if (columnsConfig) {
    const { col0, col1, col2 } = columnsConfig;
    return (
      <tr>
        <th
          className={classNames('pointer tl', col0.className)}
          onClick={() =>
            navigate({ pathname, search: getNewQuery(query, type, col0.property, key) })
          }
        >
          <div style={{ display: 'flex', color: 'black', alignItems: 'center' }}>
            <Section>{col0.label ? trans(col0.label) : type} </Section>
            <SortButton field={col0.property} type={type} query={query} />
          </div>
        </th>
        {col1 ? (
          <th
            className={classNames('pointer tl', col1.className)}
            style={{
              color: 'black',
              wordWrap: 'break-word',
              wordBreak: 'break-word',
              verticalAlign: 'middle',
              width: col1.width,
            }}
            onClick={() =>
              navigate({ pathname, search: getNewQuery(query, type, col1.property, key) })
            }
          >
            {col1.label ? trans(col1.label) : col1.property}
            <SortButton field={col1.property} type={type} query={query} />
          </th>
        ) : (
          <th className="hidden-xs" style={{ width: '5rem', color: 'black' }} />
        )}
        {col2 ? (
          <th
            className={classNames('pointer tl', col2.className)}
            style={{
              color: 'black',
              wordWrap: 'break-word',
              wordBreak: 'break-word',
              verticalAlign: 'middle',
              width: col2.width,
            }}
            onClick={() =>
              navigate({ pathname, search: getNewQuery(query, type, col2.property, key) })
            }
          >
            {col2.label ? trans(col2.label) : col2.property}
            <SortButton field={col2.property} type={type} query={query} />
          </th>
        ) : (
          <th className="hidden-xs" style={{ width: '5rem', color: 'black' }} />
        )}
      </tr>
    );
  }
  const newQuery = getNewQuery(query, type, 'n005t_Bezeichnung', key);
  return (
    <tr>
      <th
        colSpan="3"
        className="pointer tl"
        style={{ color: 'black', display: 'flex' }}
        onClick={() => navigate({ pathname, search: newQuery })}
      >
        {type}
        <SortButton field={newQuery.field} type={type} query={query} />
      </th>
    </tr>
  );
}


function RenderCol({ col, value, formatDate }) {
  if (col.type !== 'date') {
    return value
  }
  if (!value) {
    return ''
  }
  // console.log('val', value)
  if (value.indexOf("[") === 0) {
    return value
  }
  try {
    return formatDate(value, {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    })
  } catch (e) {
    console.log('Error Formating as Date', value)
    return 'Ungültiges Datum'
  }
}

function ObjectListItem({ item, db, previousUrl, columnsConfig }) {
  const { formatDate } = useIntl()
  const style = useMemo(() => {
    const style = {
      color: '#34434A',
    };
    if (item.f025b_Aktuell === '0') {
      style.color = '#EE7600';
    }
    if (item.f025b_Aktuell === '2') {
      style.color = '#009051';
    }
    return style
  }, [item.f025b_Aktuell])
  if (columnsConfig) {
    const { col0, col1, col2 } = columnsConfig;
    return (
      <tr>
        <td
          className={classNames('tl', col0.className)}
          style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}
        >
          <Link
            style={style}
            to={{
              pathname: `/object/${item.f001t_Typ}/${item.a001t_ID_Objekt}/${db}`,
              search: querystring.stringify({ prev: previousUrl }),
            }}
          >
            {item[col0.property]}
          </Link>
        </td>
        {col1 ? (
          <td
            className={classNames('tl', col1.className)}
            style={{ wordWrap: 'break-word', wordBreak: 'break-word', width: '21rem' }}
          >
            <RenderCol col={col1} value={item[col1.property]} formatDate={formatDate} />
          </td>
        ) : (
          <td className="hidden-xs" />
        )}
        {col2 ? (
          <td
            className={classNames('tl', col2.className)}
            style={{ wordWrap: 'break-word', wordBreak: 'break-word', width: '10rem' }}
          >
            <RenderCol col={col2} value={item[col2.property]} formatDate={formatDate} />
          </td>
        ) : (
          <td className="hidden-xs" />
        )}
      </tr>
    );
  }
  return (
    <tr>
      <td colSpan="3" className="tl">
        <Link
          style={style}
          to={{
            pathname: `/object/${item.f001t_Typ}/${item.a001t_ID_Objekt}/${db}`,
            search: querystring.stringify({ prev: previousUrl }),
          }}
        >
          {item.n005t_Bezeichnung}
        </Link>
      </td>
    </tr>
  );
}