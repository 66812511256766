import React, { useState, useCallback, useMemo } from 'react';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import SearchBar from './SearchBar';
import trans, { translatedTextForObject } from './trans';
import CookieQuestion from './CookieQuestion';

const SpanPoweredBy = styled.span`
  font-size: 12px;
  min-width: 70px;
  text-align: right;
  color: #000;
`;
const PoweredByContainer = styled.div`
  padding: 0 10px;
  position: relative;
  width: 200px;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  @media (max-width: 768px) {
    display: none;
  }
`;
const Holder = styled.div`
  position: sticky;
  top: 0;
  z-index: 99;
  width: 100%;
`;

const HeaderContainer = styled.div`
  position: relative;
  top: 0;
  z-index: 3;
  background-color: ${(props) => props.theme.navBarBgColor};
  border-bottom: ${(props) => `3px solid ${props.theme.NavBarBorderBottom}`};
  border-top-color: ${(props) => props.theme.navBarBorderColor};
  border-left-color: ${(props) => props.theme.navBarBorderColor};
  width: 100%;
  max-width: 100%;

  @media (min-width: ${(props) => props.theme.navbarBreakPoint}) {
    .navbar-collapse {
      padding: 0;
    }
  }
  @media (max-width: 991px) {
    .navbar-nav > li > a {
      padding: 15px 10px;
    }
  }
`;

const HeaderImg = styled.img`
  max-height: 40px;
  @media (max-width: 360px) {
    max-height: 12px;
  }
`;

const NavBarItem = styled(Link)`
  background-color: ${(props) => props.theme.navBarBgColor};
  color: ${(props) => props.theme.NavbarItem};
  padding: 10px;
  text-decoration: none;
  margin-top: 12px;
  border-bottom: ${({ theme, isactiveitem }) =>
    `12px solid ${isactiveitem ? theme.NavbarItemBg : theme.navBarBgColor};`}
  color: ${(props) => (props.isactiveitem ? props.theme.NavbarItem : 'none')};
  background-color: ${(props) =>
    props.isactiveitem ? props.theme.navBarBgColor : props.theme.navBarBgColor};
  font-weight: ${(props) => (props.isactiveitem ? 'bold' : 'normal')};
  cursor: pointer;
  :hover,
  :active,
  :focus {
    text-decoration: none;
    color: grey;
    border-bottom: ${(props) => `12px solid ${props.theme.NavbarItemBg}`};
    color: ${(props) => props.theme.NavbarItem};
  }
  @media (max-width: ${(props) => props.theme.navbarBreakPoint}) {
    background-color: ${(props) =>
    props.isactiveitem ? props.theme.primary : props.theme.navBarBgColor};
    border-bottom: none;
    :hover,
    :active,
    :focus {
      background-color: ${(props) => (props.isactiveitem ? props.theme.primary : '#f4f4f4')};
      color: black;
      border-bottom: none;
    }
  }
`;

const ItemsContainer = styled.div`
  display: ${(props) => (props.$showOnLarge ? 'flex' : 'none')};
  flex-direction: row;
  margin-left: 25px;
  @media (max-width: ${(props) => props.theme.navbarBreakPoint}) {
    margin-left: 0px;
    flex-direction: column;
    display: ${(props) => (props.$collapsed && props.$showOnSmall ? 'flex' : 'none')};
    background-color: ${(props) => props.theme.navBarBgColor};
    width: 100%;
    padding-bottom: 10px;
    border-bottom: ${(props) => `3px solid ${props.theme.NavBarBorderBottom}`};
  }
`;
const RightHeaderBlock = styled.div`
  display: flex;
  align-items: center;
`;

const CollapseItemsButton = styled.button`
  display: none;
  background-color: ${(props) => (props.$collapsed ? '#f2f3f4' : 'transparent')};
  @media (max-width: ${(props) => props.theme.navbarBreakPoint}) {
    display: block;
  }
`;
const HolderContainer = styled.div`
  position: relative;
`;
const DivContainer = styled.div`
  display: flex;
`;
const NavBar = styled.div`
  max-width: 1280px;
  height: 65px;
  margin: 0 auto;
  border-right: 0px;
  border-radius: 0px;
  z-index: 100000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: ${(props) => props.theme.navbarBreakPoint}) {
    padding: 15px 5px;
  }
`;
const LogoContainer = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const IconsContainer = styled.div`
  display: flex;
  align-items: center;
`;

const IconMenu = styled.span`
  font-size: 23px;
`;


function NavBarItemNews({ close, item, pathname, locationSearchParsedPrev = '' }) {
  // console.log('locationSearchParsedPrev', pathname, locationSearchParsedPrev)
  const myurl = `/news/${translatedTextForObject(item.name)}`
  const isactiveitem = useMemo(() => {
    if (pathname.includes(myurl)) {
      return true
    }
    if (locationSearchParsedPrev && locationSearchParsedPrev.includes(myurl)) {
      return true
    }
    return false
  }, [locationSearchParsedPrev, myurl, pathname])
  return <NavBarItem
    onClick={() => close && close()}
    to={`/news/${item.name.de}/`}
    isactiveitem={
      isactiveitem ? 'active' : null
    }
  >
    {translatedTextForObject(item.name)}
  </NavBarItem>
}

function NavBarItemMenu({ close, item, pathname, locationSearchParsedPrev = '' }) {
  const myurl = encodeURIComponent(item.url)
  // console.log('myurl', myurl, pathname)
  const isactiveitem = useMemo(() => {
    if (pathname.includes(myurl)) {
      return true
    }
    if (locationSearchParsedPrev && locationSearchParsedPrev.includes(myurl)) {
      return true
    }
    return false
  }, [locationSearchParsedPrev, myurl, pathname])
  return <NavBarItem
    style={{ height: '100%!important' }}
    onClick={() => close && close()}
    to={`/${item.url}/`}
    isactiveitem={isactiveitem ? 'active' : null}
  >
    {translatedTextForObject(item.name)}
  </NavBarItem>
}


export default function Header({ currentuserIsLoggedIn }) {
  const location = useLocation()
  const config = useSelector((state) => state.config);

  const {
    loginAndRegistration,
    logoText,
    searchHideForAnonymous,
    theme,
    newsList: { tabs },
    menu,
  } = config;

  const { displayInfosLogin } = loginAndRegistration

  const firstTab = useMemo(() => tabs && tabs[0].name.de, [tabs]);
  const { pathname } = location;

  const [collapsed, setCollapsed] = useState(false);

  const [searchParams] = useSearchParams()
  const locationSearchParsedPrev = searchParams.get("prev")

  const tabsFiltered = useMemo(() => tabs.filter((item) => !item.private || currentuserIsLoggedIn), [tabs, currentuserIsLoggedIn])
  const menuFiltered = useMemo(() => menu.filter((item) => !item.private || currentuserIsLoggedIn), [menu, currentuserIsLoggedIn])

  const close = useCallback(() => setCollapsed(false), [])

  if (!menu) {
    return null; // <div>...</div>;
  }

  return (
    <Holder>
      <HeaderContainer>
        <CookieQuestion theme={theme} />
        <HolderContainer>
          <NavBar>
            <RightHeaderBlock>
              <CollapseItemsButton
                className="btn button-default"
                $collapsed={collapsed}
                onClick={() => setCollapsed(!collapsed)}
              >
                <IconMenu className="glyphicon glyphicon-menu-hamburger" />
              </CollapseItemsButton>
              <LogoContainer to={`/news/${firstTab}/`}>
                <HeaderImg alt="" src={theme.logo} />
              </LogoContainer>
              <ItemsContainer $collapsed={collapsed} $showOnLarge>
                {tabsFiltered.map((item, index) => (
                  <NavBarItemNews close={close} item={item} pathname={pathname} key={`menu-${index + 1}`} locationSearchParsedPrev={locationSearchParsedPrev} />
                ))}
                {menuFiltered.map((item, index) => (
                  <NavBarItemMenu close={close} item={item} pathname={pathname} key={`menu-${index + 1}`} locationSearchParsedPrev={locationSearchParsedPrev} />
                ))}
              </ItemsContainer>
            </RightHeaderBlock>
            <DivContainer>
              {searchHideForAnonymous && (!currentuserIsLoggedIn) ? null : (
                <SearchBar />
              )}
              <IconsContainer>
                {displayInfosLogin ?
                  currentuserIsLoggedIn ? (
                    <div
                      style={{
                        fontSize: 16,
                        width: 24,
                        height: 24,
                        cursor: 'pointer',
                        marginRight: '4em',
                      }}
                      onClick={() => {
                        localStorage.removeItem('infos_access_token');
                        window.location.reload()
                      }}
                      title={trans('menu.logout')}
                    >
                      <svg focusable="false" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                        <path d="M10.09 15.59L11.5 17l5-5-5-5-1.41 1.41L12.67 11H3v2h9.67l-2.58 2.59zM19 3H5c-1.11 0-2 .9-2 2v4h2V5h14v14H5v-4H3v4c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z"></path>
                      </svg>
                    </div>
                  ) : <span style={{ cursor: 'pointer' }}><div><Link
                    to="/infoslogin/"
                    className="btn btn-default"
                    style={{
                      backgroundColor: theme.navBarLoginBtnColor,
                      color: theme.navBarLoginBtnText,
                      border: 'none',
                    }}
                  >
                    {trans('menu.info_login')}
                  </Link></div></span>
                  : null}
                <PoweredByContainer>
                  <SpanPoweredBy>{translatedTextForObject(logoText)}</SpanPoweredBy>
                  <a href="https://www.carhs.de" target="_blank" rel="noopener noreferrer">
                    <img src={theme.poweredByLogo} style={{ maxWidth: '125px' }} alt="carhs logo" />
                  </a>
                </PoweredByContainer>
              </IconsContainer>
            </DivContainer>
          </NavBar>
        </HolderContainer>
      </HeaderContainer>
      <ItemsContainer $collapsed={collapsed} $showOnSmall>
        {tabsFiltered.map((item, index) => (
          <NavBarItemNews close={close} item={item} pathname={pathname} key={`menu-${index + 1}`} locationSearchParsedPrev={locationSearchParsedPrev} />
        ))}
        {menuFiltered.map((item, index) => (
          <NavBarItemMenu close={close} item={item} pathname={pathname} key={`menu-${index + 1}`} locationSearchParsedPrev={locationSearchParsedPrev} />
        ))}
        <NavBarItem
          style={{ height: '100%!important' }}
          onClick={() => close && close()}
          to="/latest_updates/"
          isactiveitem={pathname === '/latest_updates/' ? 'active' : null}
        >
          LATEST UPDATES
        </NavBarItem>
      </ItemsContainer>
    </Holder>
  );
}
