import { useState, useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import get from './actions/api';


export default function useUserIsLoggedInCheck() {
  const [currentuserIsLoggedIn, setInfoIsLoggedIn] = useState(false);

  const navigate = useNavigate()
  const config = useSelector((state) => state.config);

  const {
    loginAndRegistration,
    newsList: { tabs },
    menu,
    objectSingleUrlTemplatePrivate
  } = config;

  const { displayInfosLogin, localLoginUrlBase } = loginAndRegistration

  const {
    pageName, // <-- detect "is menu tab"
    newsId, // <-- detect "is news tab"
    db, // objectType, objectID
  } = useParams()

  const checkInfosUserIsLoggedIn = useMemo(() => {
    if (!displayInfosLogin) {
      return false
    }

    if (newsId) {
      const privateNewstabsNewsids = tabs.filter(t => t.private).map(t => t.name.de)
      if (privateNewstabsNewsids.includes(newsId)) {
        return true
      }
    }

    if (pageName) {
      const privateMenuEntries = menu.filter(m => m.private === true).map(m => m.url)
      if (privateMenuEntries.includes(pageName)) {
        return true
      }
    }

    if (db && objectSingleUrlTemplatePrivate && objectSingleUrlTemplatePrivate.includes(db)) { // we can assume we are in ObjectSingle
      return true
    }

    return false
  }, [displayInfosLogin, newsId, pageName, db, objectSingleUrlTemplatePrivate, tabs, menu])

  useEffect(() => {
    if (!displayInfosLogin) {
      return;
    }

    console.log('checkInfosUserIsLoggedIn', checkInfosUserIsLoggedIn)

    const executeInfosTokenVerify = async () => {
      const token = localStorage.getItem('infos_access_token');
      // console.log('token', token)
      if (!token) {
        setInfoIsLoggedIn(false)
        if (checkInfosUserIsLoggedIn) {
          navigate('/')
        }
        return
      }
      let verified
      try {
        const response = await get({ url: `${localLoginUrlBase}infosjwtverify`, query: { token } });
        verified = response.verify
      } catch (err) {
        console.log(err)
        verified = false
      }

      if (!verified) {
        localStorage.removeItem('infos_access_token');
        console.log('infosjwtverify returned false, token was removed')
        if (checkInfosUserIsLoggedIn) {
          navigate('/')
        }
      }
      setInfoIsLoggedIn(verified);
    };
    executeInfosTokenVerify();
  }, [
    displayInfosLogin,
    localLoginUrlBase,
    setInfoIsLoggedIn,
    checkInfosUserIsLoggedIn,
    navigate,
  ]);

  return currentuserIsLoggedIn
}
