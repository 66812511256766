import React from 'react';
import styled from 'styled-components';
import YouTube from 'react-youtube';

const VideoContainer = styled.div`
  margin-top: 20px;
  width: 100%;
`;

export default function Video({ video, isYoutube }) {
  if (!video) {
    return null;
  }
  const opts = {
    width: '100%',
    height: 300,
  };

  return (
    <VideoContainer>
      {isYoutube ? (
        <YouTube videoId={video} opts={opts} id={video} />
      ) : (
        <video style={{ height: 300, width: '100%' }} controls name="media">
          <source src={video} type="video/mp4" />
        </video>
      )}
    </VideoContainer>
  );
}
