import { createAction, handleActions } from 'redux-actions';
import { combineReducers } from 'redux';

const SET_PROFIL = 'SET_PROFIL';

const setProfil = createAction(SET_PROFIL);

const dataReducer = handleActions(
  {
    [SET_PROFIL]: (state, action) => action.payload,
  },
  {}
);

export const profilReduce = combineReducers({ data: dataReducer });

export function setUserProfil(data) {
  return (dispatch) => {
    dispatch(setProfil(data));
  };
}
