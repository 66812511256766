import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import BannerComponentStyle from '../styles/BannerComponent';
import SupporterBox from '../components/SupporterBox';
import EntriesList from '../components/EntriesList';
import LastEntriesList from '../components/LastEntriesList';
import { useLocation } from 'react-router-dom';

const PageContainer = styled.div`
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;
  flex-direction: column;
  display: flex;
  justify-content: space-between;
  @media (max-width: 1250px) {
    padding: 10px;
  }
  @media (max-width: 960px) {
    flex-direction: column;
  }
`;

const CustomColumsColum = styled.div`
  order: 2;
  display: flex;
  justify-content: space-between;
  background-color: #f4f5f7 !important;
  @media (max-width: 1250px) {
    flex-direction: column;
    margin-top: 0px;
  }
`;

const ConfigColumn = styled.div`
  order: 2;
  flex: 1;
  padding-right: 20px;
  @media (max-width: 1250px) {
    flex: 2;
    padding: 10px;
  }
  @media (max-width: 960px) {
    order: 1;
    padding: 10px;
    justify-content: space-between;
    flex-direction: column;
  }
`;

function RenderWidget({ widgetConf, pathname }) {
  if (widgetConf.type === 'supporterBoxWidget') {
    return <SupporterBox {...widgetConf} />;
  }
  if (widgetConf.type === 'entriesList') {
    return <EntriesList {...widgetConf} />;
  }
  if (widgetConf.type === 'lastEntriesList') {
    return (
      <LastEntriesList isMobilePage pathname={pathname} {...widgetConf} />
    );
  }
  return null;
}

export default function LatestUpdatesPage(props) {
  const { pathname } = useLocation()
  const customSecondColumnWidgets = useSelector(state => state.config.customSecondColumnWidgets)

  return (
    <BannerComponentStyle>
      <PageContainer className="BannerComponent">
        <ConfigColumn pathname={pathname}>
          <div>
            {customSecondColumnWidgets
              .filter((p) => p.position === 'top')
              .map((widgetConf, idx) => <RenderWidget key={idx} widgetConf={widgetConf} pathname={pathname} />)}
          </div>
          <CustomColumsColum>
            <div style={{ flex: 1, padding: '0 15px 15px 15px' }}>
              {customSecondColumnWidgets
                .filter((p) => p.position === 'left')
                .map((widgetConf, idx) => <RenderWidget key={idx} widgetConf={widgetConf} pathname={pathname} />)}
            </div>
          </CustomColumsColum>
        </ConfigColumn>
      </PageContainer>
    </BannerComponentStyle>
  );
};
