import { useEffect, useState } from 'react';

export function useScrollPosition(el) {
  const [position, setPosition] = useState(null);
  useEffect(() => {
    const listener = () => setPosition(el && el.scrollTop);
    if (el) {
      el.addEventListener('scroll', listener);
      return () => {
        el.removeEventListener('scroll', listener);
      };
    }
  }, [el]);
  return position;
}

export function useWindowEventListener(name, handler) {
  useEffect(() => {
    window.addEventListener(name, handler);
    return () => window.removeEventListener(name, handler);
  }, [name, handler]);
}
