import React, { lazy, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import stringFormat from 'string-format';
import { Helmet } from 'react-helmet';
import { resetObject, setObject } from '../actions/object';
import { setRoute } from '../actions/config';
import apiGet from '../actions/api';
import trans from '../components/trans';
import { useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';

const ObjectSingle = lazy(() => import(/* webpackChunkName: 'ObjectSingle' */
  '../components/ObjectSingle'));


export default function ObjectSinglePage() {
  const dispatch = useDispatch()
  const objectDetails = useSelector(state => state.object.objectDetails)
  const objectSingleUrlTemplate = useSelector(state => state.config.objectSingleUrlTemplate)
  const { db, objectType, objectID } = useParams()

  const [searchParams] = useSearchParams()
  const locationSearchParsedPrev = searchParams.get("prev")

  useEffect(() => {
    const urltmpl = objectSingleUrlTemplate[db]
    if (!urltmpl) {
      return
    }

    const url = stringFormat(objectSingleUrlTemplate[db], db, objectType, objectID);

    dispatch(resetObject());

    apiGet({ url }).then((response) => {
      dispatch(setObject({ ...response.data[0], f001t_Typ: objectType }));
    });
    if (locationSearchParsedPrev) {
      dispatch(setRoute(locationSearchParsedPrev));
    }
  }, [
    db,
    locationSearchParsedPrev,
    objectID,
    objectSingleUrlTemplate,
    objectType,
    dispatch
  ]);

  return (
    <>
      <ObjectSingle
        object={objectDetails}
        locationSearchParsedPrev={locationSearchParsedPrev}
      />
      <Helmet title={objectDetails ? objectDetails.n100t_Titel : trans('breadcrumb.object-single')} />
    </>
  );
}
