import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import stringFormat from 'string-format';

import InfiniteScroll from 'react-infinite-scroller';
import trans from '../components/trans';
import backendQuery from '../actions/backendQuery';
import NewsList from '../components/NewsList';
import { mapResposeData } from '../components/tagsUtils';
import BackButton from '../components/BackButton';
import BannerComponent from '../components/BannerComponent';
import getNavigatorLanguage from '../actions/getNavigatorLanguage';

export default function TagsListPage() {
  const { tagId, newsId } = useParams()
  const tabs = useSelector((state) => state.config.newsList.tabs);

  const [tagsData, setTagsData] = useState({
    moreTags: false,
    nextTags: '',
    tagsList: [],
    origin: null,
  });
  const { moreTags, nextTags, tagsList } = tagsData;

  const loadTagsData = useCallback(
    ({ url, reset, extra }) => {
      const language = getNavigatorLanguage();
      backendQuery({
        url: url,
        RFMscriptParam: `${language},${tagId}`,
        ...extra,
      }).then((resp) => {
        const hasMoreTags = resp.nav.next && resp.nav.next.length > 0;
        setTagsData((state) => ({
          ...state,
          moreTags: hasMoreTags,
          tagsList: reset
            ? [...mapResposeData(resp.data)]
            : [...state.tagsList, ...mapResposeData(resp.data)],
          nextTags: hasMoreTags ? resp.nav.next : null,
        }));
      });
    },
    [tagId, setTagsData]
  );

  const tab = useMemo(() => tabs.find((t) => t.name.de === newsId) || tabs[0], [tabs, newsId]);

  const loadMore = useCallback(
    () => loadTagsData({ url: `${tab.baseURL}${nextTags}`, extra: {}, reset: false }),
    [loadTagsData, tab, nextTags]
  );
  const reloadNewForNewTag = useCallback(
    (url) => loadTagsData({ url, extra: { RFMmax: 10, RFMscript: tab.RFMscript }, reset: true }),
    [loadTagsData, tab.RFMscript]
  );

  const url = useMemo(() => stringFormat(tab.url, tab.db), [tab]);

  useEffect(() => {
    reloadNewForNewTag(url);
  }, [reloadNewForNewTag, url]);

  return (
    <>
      <BannerComponent>
        <InfiniteScroll
          pageStart={0}
          loadMore={loadMore}
          hasMore={moreTags}
          loader={
            <div key="key" className="ma2 b tc">
              {trans('loader.loading')}
            </div>
          }
        >
          <NewsList newsId={newsId} newsItems={tagsList} db={tab.db} />
        </InfiniteScroll>
        {moreTags ? null : (
          <div style={{ padding: '10px' }}>
            <BackButton to={`/news/${newsId}/`}>« {trans('BACK_TO_OVERVIEW')}</BackButton>
          </div>
        )}
      </BannerComponent>
    </>
  );
}
