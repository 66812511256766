// import ReactDOM from 'react-dom';
import { createStore as reduxCreateStore, applyMiddleware } from 'redux';
// import { logger } from 'redux-logger';
import promiseMiddleware from 'redux-promise';
import { thunk } from 'redux-thunk'
import rootReducer from './reducers';

// function batchedUpdatesMiddleware() {
//   return (next) => (action) => ReactDOM.unstable_batchedUpdates(() => next(action));
// }

const middlewares = [thunk, promiseMiddleware /*, batchedUpdatesMiddleware*/];
// if (process.env.NODE_ENV === `development`) {
//   middlewares.push(logger);
// }

const createStoreWithMiddleware = applyMiddleware(...middlewares)(reduxCreateStore);

export default function createStore(initialState) {
  return createStoreWithMiddleware(rootReducer, initialState);
}
