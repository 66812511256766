import React, { useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useCookies } from 'react-cookie';
import styled from 'styled-components';

import { crossPlatformNavigatorLang } from '../components/trans';
import Checkbox from '../components/Checkbox';

function dateInXDays(days) {
  const date = new Date();
  const inMs = days * 24 * 60 * 60 * 1000;
  date.setTime(date.getTime() + inMs);
  return date;
}

const Button = styled.button`
  background-color: ${(props) => props.theme.primary};
  color: white;
`;

const NewsLetterForm = styled.div`
  border-radius: 5px;
  position: fixed;
  top: 70px;
  right: auto;
  left: 50%;
  margin-left: -37.5%;
  width: 75%;
  background-color: lightgrey;
  z-index: 100;
  box-shadow:
    0 4px 8px 0 rgba(0, 0, 0, 0.2),
    0 6px 20px 0 rgba(0, 0, 0, 0.19);
  span {
    position: absolute;
    top: 0;
    right: 0;
    margin: 5px;
  }

  h3 {
    text-align: center;
  }

  p {
    text-align: center;
  }

  button {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  label {
    display: block;
    text-align: center;

    input {
      margin-right: 5px;
    }
  }

  @media screen and (min-width: 768px) {
    right: 50px;
    bottom: 50px;
    left: auto;
    top: auto;
    width: 350px;
    height: auto;
    margin: 5px;
  }
`;

export default function NewsletterSignUp() {
  const newsletterPopup = useSelector((state) => state.config.newsletterPopup);

  const [checked, setChecked] = useState(false);
  const [closed, setClosed] = useState(false);

  const [cookies, setCookie] = useCookies(['SWNewsletter']);

  const closeForm = useCallback(() => {
    if (checked) {
      setCookie('SWNewsletter', true, { path: '/', expires: dateInXDays(365) });
    }
    setClosed(true);
  }, [checked, setCookie]);

  const signUp = useCallback(() => {
    const win = window.open('https://www.carhs.de/de/safetynews.html', '_blank');
    win.focus();
    closeForm();
  }, [closeForm]);

  if (!!cookies.SWNewsletter || closed) {
    return null;
  }

  return (
    <NewsLetterForm className="fadeIn">
      <span
        style={{ cursor: 'pointer' }}
        className="glyphicon glyphicon-remove"
        onClick={closeForm}
      />
      <h3>{newsletterPopup.heading[crossPlatformNavigatorLang()]}</h3>
      <p>{newsletterPopup.text[crossPlatformNavigatorLang()]}</p>
      <label>
        <Checkbox
          onChange={() => setChecked((prev) => !prev)}
          id="checkBox"
          type="checkbox"
          value={checked}
        />
        {newsletterPopup.checkboxText[crossPlatformNavigatorLang()]}
      </label>
      <Button className="btn center-block" id="signUpButton" onClick={signUp} disabled={false}>
        {newsletterPopup.buttonText[crossPlatformNavigatorLang()]}
      </Button>
    </NewsLetterForm>
  );
}
