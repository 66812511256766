import React, { useState, useEffect, useMemo } from 'react';
import querystring from 'query-string';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useCookies } from 'react-cookie';

import apiGet from '../actions/api';
import Section from './Section';

import { translatedTextForObject } from './trans';
import { Entry } from './EntriesList';

const CardHolder = styled.div`
  display: flex;
  align-items: center;
`;
const EntryText = styled.div`
  line-height: 1.5;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: ${({ isMobilePage }) => (isMobilePage ? 'calc(100vw - 155px)' : '210px')};
  @media (max-width: 1250px) {
    max-width: ${({ isMobilePage }) => (isMobilePage ? 'calc(100vw - 155px)' : '90px')};
  }
`;
const ImgHolder = styled.div`
  position: relative;
  width: 70px;
  min-width: 70px;
  height: 20px;
  overflow: hidden;
  margin-right: 10px;
`;
const Img = styled.img`
  height: 100%;
  position: absolute;
  top: -50%;
  left: -50%;
  right: -50%;
  bottom: -50%;
  margin: auto auto;
`;
const TextHolder = styled.div`
  display: flex;
`;
const InfoHolder = styled.div`
  display: flex;
  align-items: flex-start;
  flex-flow: ${({ isMobilePage }) => isMobilePage && 'column nowrap'};
  overflow: hidden;
`;
const Dot = styled.span`
  margin-right: 5px;
`;
const DateText = styled.span`
  color: rgb(140, 140, 140);
  font-size: ${({ isMobilePage }) => isMobilePage && '12px'};
  min-width: 80px;
`;
const LatestSection = styled.div`
  overflow-y: ${({ isMobilePage }) => (isMobilePage ? 'visible' : 'scroll')};
  max-height: ${({ isMobilePage }) => (isMobilePage ? 'fit-content' : '330px')};
  & > a:nth-child(even) > div {
    background-color: ${({ isMobilePage }) => isMobilePage && '#f4f5f7'};
  }
`;

function RenderStatus({ read }) {
  return (
    <div style={read ? { color: 'grey', fontWeight: 30 } : { color: 'green', fontWeight: 30 }}>
      &#9864;
    </div>
  );
}

export default function LastEntriesList({
  url,
  title,
  limit,
  toUrl,
  db,
  sinceDays,
  pathname,
  isMobilePage,
}) {
  const [list, setList] = useState(null);
  useEffect(() => {
    const loadAndSet = async () => {
      const result = await apiGet({ url });
      const newList = result.data && result.data.splice(0, limit || result.data.length);
      setList(newList);
    };
    loadAndSet();
  }, [limit, url]);

  const [cookies] = useCookies(['last_updated']);
  const already_read_ids = useMemo(() => {
    const save_ids_cookie = cookies.last_updated;
    return querystring.parse(save_ids_cookie);
  }, [cookies.last_updated])

  const sinceDaysDateobj = useMemo(() => {
    const date = new Date();
    date.setDate(date.getDate() - sinceDays);
    return date;
  }, [sinceDays]);

  const filteredList = useMemo(() => {
    if (!list) {
      return [];
    }
    return list.filter((entry) => {
      return new Date(entry.fs05ts_ErstelltAm_ISO) - sinceDaysDateobj >= 0;
    });
  }, [list, sinceDaysDateobj]);

  const search = useMemo(() => querystring.stringify({
    prev: pathname,
  }), [pathname]);

  if (!list) {
    return null;
  }

  return (
    <div style={{ marginBottom: 15, background: '#f4f5f7' }}>
      <div style={{ padding: 10 }}>
        <Section $margin="0px">{translatedTextForObject(title)}</Section>
      </div>
      <LatestSection isMobilePage={isMobilePage}>
        {filteredList.map((entry) => (
          <Link
            key={entry.a001t_GUID}
            style={{ textDecoration: 'none' }}
            to={{
              pathname: `${toUrl}${entry.a001t_GUID}/${db}`,
              search,
            }}
          >
            <Entry isMobilePage={isMobilePage}>
              <CardHolder>
                <ImgHolder>
                  <Img src={entry.n018t_Vorschaubild_URL} alt={entry.label} />
                </ImgHolder>
                <InfoHolder isMobilePage={isMobilePage}>
                  <DateText isMobilePage={isMobilePage}>{entry.fs05ts_ErstelltAm_ISO}</DateText>
                  {isMobilePage ? (
                    <TextHolder>
                      <Dot>
                        <RenderStatus
                          read={Object.values(already_read_ids).indexOf(entry.a001t_GUID) > -1}
                        />
                      </Dot>
                      <EntryText
                        title={entry.f046t_Bezeichnung_Latest_Docs}
                        isMobilePage={isMobilePage}
                      >
                        {entry.f046t_Bezeichnung_Latest_Docs}
                      </EntryText>
                    </TextHolder>
                  ) : (
                    <>
                      <Dot>
                        <RenderStatus
                          read={Object.values(already_read_ids).indexOf(entry.a001t_GUID) > -1}
                        />
                      </Dot>
                      <EntryText
                        title={entry.f046t_Bezeichnung_Latest_Docs}
                        isMobilePage={isMobilePage}
                      >
                        {entry.f046t_Bezeichnung_Latest_Docs}
                      </EntryText>
                    </>
                  )}
                </InfoHolder>
              </CardHolder>
            </Entry>
          </Link>
        ))}
      </LatestSection>
    </div>
  );
}
