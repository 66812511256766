import React, { useMemo, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Affix } from 'react-overlays';
import { useSelector } from 'react-redux';

import { useCookies } from 'react-cookie';
import TreeMenuItem from './TreeMenuItem';
import Video from './Video';
import { getVideoParamsMenu } from './trans';

const TreeMenuStyles = styled.div`
  .dropdown-menu > li > a {
    padding: 0 !important;
  }

  .list-group-item {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .TreeMenu {
    padding: 15px;
    border-bottom-style: solid;
    border-bottom-width: 5px;

    &.affix-top {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
    }

    &.affix {
      width: 100%;
      padding: 10px 0;
      padding-left: 260px;
      border-bottom: 3px solid #23b428 !important;
      position: fixed;
      z-index: 1;
      top: 67px;
      height: 65px;
      max-width: 1280px;
      .list-group-item.active {
        margin-bottom: 0;
        min-height: 42px;
      }
      .list-group-item:not(.active) {
        display: none;
      }
      img.hide-affix,
      .col-img {
        display: none;
      }
      &.TopSearchBar {
        &.WithOffsetCookiebar {
          top: 105px;
          height: 65px;
        }
      }
      &.WithOffsetCookiebar {
        top: 93px;
        height: 65px;
      }
    }

    .Select {
      margin-bottom: 10px;
    }
    @media only screen and (min-width: 1200px) {
      &.affix {
        width: 100%;
        left: auto;
        right: auto;
      }
    }
    @media only screen and (min-width: 768px) and (max-width: 992px) {
      &.affix {
        top: 151px;
      }
    }
  }
  .search-results {
    margin-top: 20px;
    padding: 0px;
    .border-md-lg {
      border: 1px solid;
    }
  }
  @media only screen and (min-width: 768px) {
    .search-results {
      // margin-top: 360px;
      padding: 15px;

      // .TrainingListPage & {
      //   margin-top: 270px;
      // }
    }
  }
  .TreeMenu {
    .col-md-4 {
      @media (min-width: 992px) {
        width: ${(props) => props.$isMenuTabTools && '24.999999%!important'};
      }
    }
  }

  .TreeMenu {
    .Select-option {
      padding: 0;

      > div {
        padding: 8px 10px;
        border-bottom: 1px solid #ccc;

        &.active {
          background-color: #89a;
          color: #fff;

          &:hover,
          &:focus {
            background-color: #337ab7;
          }
        }
      }
    }
  }
`;

function checkUseMobileView() {
  // eslint-disable-next-line no-unneeded-ternary
  return window.innerWidth <= 992 ? true : false;
}

function useMobileView() {
  const [mobileState, setMobileState] = useState(checkUseMobileView());
  useEffect(() => {
    const resizeHandler = () => setMobileState(checkUseMobileView());
    window.addEventListener('resize', resizeHandler);
    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, []);
  return mobileState;
}


function getAffixClass(cookieBarIsActive, searchHideForAnonymous) {
  if (!searchHideForAnonymous && cookieBarIsActive) {
    return 'TopSearchBar WithOffsetCookiebar';
  }
  if (!searchHideForAnonymous && !cookieBarIsActive) {
    return 'TopSearchBar';
  }
  if (searchHideForAnonymous && cookieBarIsActive) {
    return 'WithOffsetCookiebar';
  }
  return 'TopWithoutSearchBar';
}

function GetTreeWithAffix({
  lastMenuEntry,
  treeMenus,
  styles,
  searchHideForAnonymous,
  position,
}) {
  const [cookies] = useCookies(['Opt-Out-Cookie']);
  const cookieBarIsActive = typeof cookies['Opt-Out-Cookie'] === 'undefined';

  const affixClass = useMemo(
    () => getAffixClass(cookieBarIsActive, searchHideForAnonymous),
    [searchHideForAnonymous, cookieBarIsActive]
  );
  const className = `clearfix border-md-lg ${affixClass}`;
  const videoParams = useMemo(() => getVideoParamsMenu(lastMenuEntry), [lastMenuEntry]);
  return (
    <Affix
      topStyle={styles.TreeMenu}
      affixStyle={styles.TreeMenu}
      topClassName="TreeMenu affix-top"
      affixClassName="TreeMenu affix"
      offsetTop={videoParams ? 600 : 360}
    >
      <div id="affixTree" className={className}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div>{treeMenus}</div>
          {position < 600 && videoParams && (
            <Video video={videoParams.video} isYoutube={videoParams.isYoutube} />
          )}
        </div>
      </div>
    </Affix>
  );
}

function GetTreeWithNoAffix({ lastMenuEntry, treeMenus, styles }) {
  const videoParams = useMemo(() => getVideoParamsMenu(lastMenuEntry), [lastMenuEntry]);
  return (
    <div
      style={{ ...styles.TreeMenu, ...styles.borderMdLg }}
      className="TreeMenu clearfix border-md-lg TopWithoutSearchBar"
    >
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div>{treeMenus}</div>
        {videoParams && <Video video={videoParams.video} isYoutube={videoParams.isYoutube} />}
      </div>
    </div>
  );
}

export function useScrollPosition() {
  const [position, setPosition] = useState(null);
  useEffect(() => {
    const listener = () => setPosition(document.documentElement.scrollTop);
    document.addEventListener('scroll', listener);
    return () => {
      document.removeEventListener('scroll', listener);
    };
  }, []);
  return position;
}

export default function TreeMenu({
  isMenuTabTools,
  columnsClassName = 'col-md-2',
  displayDocumentCount,
  TreeMenuImageClass = 'col-md-4',
  columns,
  selected,
}) {
  const searchHideForAnonymous = useSelector((state) => state.config.searchHideForAnonymous);
  const theme = useSelector((state) => state.config.theme);

  const mobileState = useMobileView();
  const position = useScrollPosition();
  const styles = useMemo(
    () => ({
      TreeMenu: {
        backgroundColor: theme.TreeMenuBg,
        width: '100%',
        borderBottom: `3px solid ${theme.TreeMenuBorderBottomColor}`,
      },
      borderMdLg: {
        borderColor: theme.searchResultsBorderMdLg,
      },
    }),
    [theme]
  );

  const activeIDs = useMemo(() => columns.map((c) => c.id), [columns]);

  const treeMenus = useMemo(
    () =>
      columns.map((column, level) => (
        <TreeMenuItem
          mobileState={mobileState}
          key={`menu-level-${level}`}
          columnsClassName={columnsClassName}
          level={level}
          menu={column}
          active={activeIDs[level + 1]}
          selected={selected}
          displayDocumentCount={displayDocumentCount}
          TreeMenuImageClass={TreeMenuImageClass}
        />
      )),
    [
      TreeMenuImageClass,
      activeIDs,
      columns,
      columnsClassName,
      displayDocumentCount,
      mobileState,
      selected,
    ]
  );
  const lastMenuEntry = useMemo(() => columns[columns.length - 1], [columns]);

  return (
    <TreeMenuStyles $isMenuTabTools={isMenuTabTools}>
      {mobileState ? (
        <GetTreeWithNoAffix lastMenuEntry={lastMenuEntry} treeMenus={treeMenus} styles={styles} />
      ) : (
        <GetTreeWithAffix
          lastMenuEntry={lastMenuEntry}
          treeMenus={treeMenus}
          styles={styles}
          searchHideForAnonymous={searchHideForAnonymous}
          position={position}
        />
      )}
    </TreeMenuStyles>
  );
}
