import React, { useRef, useState, useCallback } from 'react';
import styled from 'styled-components';
import Select from 'react-select';
import ReactSelectStyle from '../styles/ReactSelect';
import { useScrollPosition, useWindowEventListener } from './utils';

const Tooltiptext = styled.span`
  visibility: hidden;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 10px;
  border-radius: 6px;
  position: absolute;
  margin-top: ${({ $pt }) => `-${$pt}px`};
 
  z-index: 1;
  &:after {
    content: " ";
    position: absolute;
    bottom: 100%;  /* At the top of the tooltip */
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent black transparent;
`;
const Tooltip = styled.div`
  // position: relative;
  &:hover ${Tooltiptext} {
    visibility: visible;
  }
`;

function RenderMenuChild({ item, active, displayDocumentCount, onSelectItem, position }) {
  const itemIsActive = item.id === active;
  const className = `list-group-item${itemIsActive ? ' active' : ''}`;
  const pfeil =
    item.children && item.children.length ? (
      <span className="pull-right">&gt;</span>
    ) : item.number && displayDocumentCount ? (
      <span className="badge">{item.number}</span>
    ) : null;

  let icon = item.icon_passive_url ? (
    <img src={item.icon_passive_url} height="20" alt="passive url" />
  ) : null;
  if (itemIsActive && item.icon_active_url) {
    icon = <img src={item.icon_active_url} height="20" alt="active url" />;
  }

  const onClick = useCallback(
    (evt) => {
      evt.preventDefault();
      onSelectItem(item);
    },
    [item, onSelectItem]
  );

  return (
    <Tooltip>
      <button
        onClick={onClick}
        className={className}
        style={{ borderRadius: '0', overflow: 'visible', position: 'inherit' }}
      >
        <div className="row">
          <div
            style={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              paddingRight: 0,
              paddingLeft: '7px',
            }}
            className="col-md-9"
          >
            {icon}&nbsp;{item.label}
          </div>
          <div className="col-md-3" style={{ paddingLeft: '7px' }}>
            {pfeil}
          </div>
        </div>
        <Tooltiptext $pt={position}>{item.label}</Tooltiptext>
      </button>
    </Tooltip>
  );
}

function SelectOptionRenderer(option) {
  return (
    <div className={option.active ? 'active' : null}>
      {option.number ? (
        <span className="badge" style={{ float: 'right' }}>
          {option.number}
        </span>
      ) : null}
      <img src={option.icon} height="20" alt="icon" />
      &nbsp;{option.label}
    </div>
  );
}

function TreeMenuItem({
  menu = [],
  active = false,
  displayDocumentCount,
  TreeMenuImageClass,
  selected,
  level = 0,
  columnsClassName,
}) {
  const containerRef = useRef(null);
  const position = useScrollPosition(containerRef.current);
  const [pageChanges, setPageChanges] = useState(0);

  const updatePage = () => {
    if (containerRef && containerRef.current && pageChanges === 0) {
      setPageChanges(1);
      containerRef.current.scrollTop =
        containerRef.current.getElementsByClassName('active')[0].offsetTop;
      containerRef.current.scrollLeft = 0;
      return;
    }
    return null;
  };

  useWindowEventListener('load', updatePage());

  if (menu.children) {
    const onSelectItem = (item) => selected(level + 1, item);

    const options = menu.children.map((item) => {
      const itemIsActive = item.id === active;
      return {
        value: item.id,
        label: item.label,
        number: item.number,
        icon: itemIsActive && item.icon_active_url ? item.icon_active_url : item.icon_passive_url,
        active: itemIsActive,
      };
    });

    const onSelectChange = (val) => {
      menu.children
        .filter((item) => `${item.id}` === val.value)
        .forEach((item) => {
          selected(level + 1, item);
        });
    };

    return (
      <ReactSelectStyle className={columnsClassName} style={{ padding: '0', marginBottom: '8px' }}>
        <Select
          value={options.find((opt) => opt.value === active)}
          placeholder=""
          className="hidden-md hidden-lg"
          options={options}
          optionRenderer={SelectOptionRenderer}
          onChange={onSelectChange}
          clearable={false}
          searchable={false}
        />
        <div
          ref={containerRef}
          className="list-group visible-md visible-lg"
          style={{ maxHeight: '300px', overflow: 'auto', margin: '0' }}
        >
          {menu.children.map((item) => (
            <RenderMenuChild
              key={item.id}
              item={item}
              active={active}
              displayDocumentCount={displayDocumentCount}
              onSelectItem={onSelectItem}
              position={position}
            />
          ))}
        </div>
      </ReactSelectStyle>
    );
  }

  if (menu.folderimage_URL) {
    return (
      <div className={`${TreeMenuImageClass} col-img`}>
        <div className="img-wrap">
          <img
            style={{ maxHeight: '300px', margin: '0 auto' }}
            className="hide-affix img-responsive"
            src={menu.folderimage_URL}
            alt="folder"
          />
        </div>
      </div>
    );
  }

  return <div />;
}
export default TreeMenuItem;
