import React, { Suspense } from 'react';
import {
  Navigate,
  Outlet,
  Route,
  RouterProvider,
  ScrollRestoration,
  createBrowserRouter,
  createRoutesFromElements,
  useParams,
  useRouteError,
} from 'react-router-dom';
// import ScrollMemory from 'react-router-scroll-memory';

import { Provider } from 'react-redux';
import { IntlProvider } from 'react-intl';
import createStore from './store';

import AppInitialization from './components/App';
import Header from './components/Header';
import Footer from './components/Footer';
import MainStyle from './styles/Main';

import NewsListPage from './pages/NewsListPage';
import SearchResultsPage from './pages/SearchResultsPage';
import LatestUpdatesPage from './pages/LatestUpdatesPage';
import PagesViewer from './pages/PagesViewer';
import Impressum from './pages/Impressum';
import FAQ from './pages/FAQ';

// import Settings from './pages/Settings';
import ObjectSinglePage from './pages/ObjectSinglePage';
import LegalNotice from './pages/LegalNotice';
import PrivacyPolicy from './pages/PrivacyPolicy';
import AboutSafetyWissen from './pages/AboutSafetyWissen';
// import LoginPage from './pages/Login';
import InfosLoginPage from './pages/InfosLogin';
import TagsListPage from './pages/TagsListPage';
import BannerStoreContextProvider from './components/BannerStoreContextProvider';

import useUserIsLoggedInCheck from './useUserIsLoggedInCheck';

// import useGoogleAnalytics from './components/useGoogleAnalytics';

function Root() {
  const currentuserIsLoggedIn = useUserIsLoggedInCheck()

  return <>
    <ScrollRestoration />
    <Header currentuserIsLoggedIn={currentuserIsLoggedIn} />
    <Outlet context={{ currentuserIsLoggedIn }} />
    <Footer />
  </>
}

function NavigateWithParams({ to, ...rest }) {
  const params = useParams();
  return <Navigate to={to(params)} {...rest} />
}


function ReactRouterWorkaroundErrorBoundary() {
  const error = useRouteError();

  return <div className="p-10 md:max-w-[50%]" style={{ padding: '40px' }}>
    <div>
      <button type="button"
        onClick={() => {
          window.location.href = '/';
        }}
      >
        Zurück
      </button>
      <div className="flex  w-full mb-6">
      </div>
      <h1 className="text-xl font-bold">Ein Fehler ist aufgetreten</h1>
      <p>Bei der Darstellung der Seite ist ein Problem aufgetreten. Sollte das Problem permanent aufgetreten, bitte wenden Sie sich an uns.</p>
      <p className="my-2">
        Kontakt:{' '}
        <a className="underline" href="mailto:info@carhs.de">
          info@carhs.de
        </a>
      </p>
    </div>
    <div className="bg-card dark:bg-gray-950 border-border mt-6">
      <div className="py-6">
        <h3>
          {error.name || error.status}: {error.message || error.data}
        </h3>
        <div>{error.stack?.toString()}</div>
      </div>
    </div>
    <div className="w-full">
    </div>
  </div>
}

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route errorElement={<ReactRouterWorkaroundErrorBoundary />}>
      <Route element={<Root />}>
        <Route exact path="/" element={<Navigate to="/news/SAFETYWISSEN" replace />} />
        <Route exact path="/news" element={<Navigate to="/news/SAFETYWISSEN" replace />} />
        <Route path="/news/:newsId" element={<NewsListPage />} />
        <Route path="/news/:newsId/tag/:tagId" element={<TagsListPage />} />
        <Route path="/latest_updates" element={<LatestUpdatesPage />} />
        <Route path="/search/" element={<SearchResultsPage />} />
        <Route path="/search" element={<Navigate to="/search/" replace />} />
        <Route path="/impressum/" element={<Impressum />} />
        <Route path="/impressum" element={<Navigate to="/impressum/" replace />} />
        <Route path="/about/" element={<AboutSafetyWissen />} />
        <Route path="/about" element={<Navigate to="/about/" replace />} />
        <Route path="/legalnotice/" element={<LegalNotice />} />
        <Route path="/legalnotice" element={<Navigate to="/legalnotice/" replace />} />
        <Route path="/privacypolicy/" element={<PrivacyPolicy />} />
        <Route path="/privacypolicy" element={<Navigate to="/privacypolicy/" replace />} />
        <Route path="/faq/" element={<FAQ />} />
        <Route path="/faq" element={<Navigate to="/faq/" replace />} />
        <Route path="/object/:objectType/:objectID/:db" element={<ObjectSinglePage />} />
        <Route path="/object/:objectType/:objectID/:db" element={<NavigateWithParams to={params => `/object/${params.objectType}/${params.objectID}/${params.db}/`}
          replace />} />
        <Route path="/object/:objectType/:objectID/" element={<NavigateWithParams to={params => `/object/${params.objectType}/${params.objectID}/safetywissen/`} replace />} />
        <Route path="/object/:objectType/:objectID" element={<NavigateWithParams to={params => `/object/${params.objectType}/${params.objectID}/safetywissen/`} replace />} />
        <Route path="/:pageName/:menuitem/" element={<PagesViewer />} />
        <Route path=":pageName" element={<PagesViewer />} />
        <Route path="/:pageName/:menuitem" element={<NavigateWithParams to={params => `/${params.pageName}/${params.menuitem}/`} replace />} />
        <Route path="/infoslogin/" element={<InfosLoginPage />} />
        <Route path="/infoslogin" element={<Navigate to="/infoslogin/" replace />} />
        {/*
      <Route path="/settings/" component={Settings} /><Redirect from="/settings" to="/settings/" />
      <Route path="/login/" component={LoginPage} />
      <Route path="/login" element={<Navigate to="/login/" replace />} />
      */}
      </Route>
    </Route>
  )
);
export const store = createStore();

export default function AppRouterWrap() {
  return <Suspense fallback={<div />}>
    <Provider store={store}>
      <IntlProvider locale="de">
        <AppInitialization>
          <BannerStoreContextProvider>
            {/* <ScrollRestoration /> */}
            <MainStyle />
            <RouterProvider router={router} />
          </BannerStoreContextProvider>
        </AppInitialization>
      </IntlProvider>
    </Provider>
  </Suspense>
}
