import { createAction, handleActions } from 'redux-actions';

const OBJECT_REQUEST = 'OBJECT_REQUEST';
const OBJECT_RESET = 'OBJECT_RESET';

export const resetObject = createAction(OBJECT_RESET);
export const setObject = createAction(OBJECT_REQUEST);

export const object = handleActions(
  {
    [OBJECT_REQUEST]: (state, { payload }) => ({ objectDetails: payload }),
    [OBJECT_RESET]: () => ({ objectDetails: null }),
  },
  { objectDetails: null }
);
