import { createAction, handleActions } from 'redux-actions';
import { mapResposeData } from '../components/tagsUtils';

const NEWS_LIST_REQUEST = 'NEWS_LIST_REQUEST';
const NEWS_MORE_REQUEST = 'NEWS_MORE_REQUEST';

export const getNewsList = createAction(NEWS_LIST_REQUEST);
export const getMoreNews = createAction(NEWS_MORE_REQUEST);

export const newsList = handleActions(
  {
    [NEWS_LIST_REQUEST]: (
      state,
      {
        payload: {
          newsId,
          nav: { next },
          data,
        },
      }
    ) => ({
      ...state,
      allNews: {
        ...state.allNews,
        [newsId]: {
          data: mapResposeData(data),
          hasMore: next,
        },
      },
    }),

    [NEWS_MORE_REQUEST]: (
      state,
      {
        payload: {
          newsId,
          nav: { next },
          data,
        },
      }
    ) => ({
      ...state,
      allNews: {
        ...state.allNews,
        [newsId]: {
          data: [...state.allNews[newsId].data, ...mapResposeData(data)],
          hasMore: next,
        },
      },
    }),
  },
  { allNews: [] }
);
