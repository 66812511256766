export function extratParamFromQuery(selected_doctypes_query) {
  if (!selected_doctypes_query) {
    return [];
  }
  if (!Array.isArray(selected_doctypes_query)) return [selected_doctypes_query];
  return selected_doctypes_query;
}

export function selStatesFromQuery(selected_states_locationQuery) {
  // console.log('111', selected_states_locationQuery);

  if (!selected_states_locationQuery) {
    // console.log('special initial case');
    return ['1', '2'];
    // return [];
  }

  if (!Array.isArray(selected_states_locationQuery)) {
    return [selected_states_locationQuery];
  }
  return selected_states_locationQuery;
}

export function build_selected_everything_for_query(
  selected_doctypes,
  selected_authors,
  selected_states
) {
  const res = {
    selected_doctypes: extratParamFromQuery(selected_doctypes),
    selected_authors: extratParamFromQuery(selected_authors),
    selected_states: selStatesFromQuery(selected_states),
  };
  return res;
}
