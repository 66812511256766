import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import trans from './trans';

const ColumnBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  flex: 1;
`;
const SiggestionTip = styled.div`
  margin: 10px 0px;
  @media (max-width: 768px) {
    margin: 0 !important;
  }
`;
const StyledTipText = styled.div`
  @media (max-width: 768px) {
    display: none;
  }
`;
const SuggestedFilterBlock = styled.div`
  display: flex;
  margin: 10px 0;
  flex-flow: row nowrap;
  max-height: 260px;
  @media (max-width: 768px) {
    flex-flow: column wrap;
  }
`;

const sections = [
  {
    title: 'Age',
    filters: ['age: 1w', 'age: 1 month', 'age: today'],
  },
  {
    title: 'Author',
    filters: ['author: ADAC', 'author: carhs'],
  },
  {
    title: 'Tools',
    filters: ['tools: Hybrid III', 'tools: simulation'],
  },
  {
    title: 'Title',
    filters: ['title: autonomous', 'title: TOP SAFETY'],
  },
  {
    title: 'Validity',
    filters: ['valid: 2018'],
  },
];

const PointerDiv = styled.div`
  cursor: pointer;
`;

function RenderFilter({ filter, addFilter }) {
  const onClick = useCallback(() => addFilter(filter), [addFilter, filter]);
  return (
    <PointerDiv key={filter} onClick={onClick}>
      {filter}
    </PointerDiv>
  );
}

function SuggestedFilterSection({ title, filters, addFilter }) {
  return (
    <ColumnBlock>
      <b>{title}</b>
      {filters.map((filter) => (
        <RenderFilter key={filter} filter={filter} addFilter={addFilter} />
      ))}
    </ColumnBlock>
  );
}

function SuggestedFilter({ addFilter }) {
  return (
    <SuggestedFilterBlock>
      {sections.map(({ title, filters }) => (
        <SuggestedFilterSection key={title} addFilter={addFilter} title={title} filters={filters} />
      ))}
    </SuggestedFilterBlock>
  );
}

export function SuggestionFilter({ addFilter }) {
  const searchTipsLink = useSelector((state) => state.config.searchTipsLink);
  return (
    <SiggestionTip>
      <StyledTipText dangerouslySetInnerHTML={{ __html: trans('SEARCH_TIPS') }} />
      {searchTipsLink ? (
        <p>
          {trans('SEARCH_TIPS_MORE')} <a href={searchTipsLink}> {trans('SEARCH_TIPS_LINK')} </a>
        </p>
      ) : null}
      <SuggestedFilter addFilter={addFilter} />
    </SiggestionTip>
  );
}

export default SuggestionFilter;
