import { createAction, handleActions } from 'redux-actions';
import apiGet from './api';

const FETCH_MENU = 'FETCH_MENU';
const RESET_MENU = 'RESET_MENU';
const FETCH_MENU_FROM_CACHE = 'FETCH_MENU_FROM_CACHE';
const APPEND_LEVEL = 'APPEND_LEVEL';

export const doFetchMenu = createAction(
  FETCH_MENU,
  (menuID) => apiGet({ url: menuID }),
  (menuID, selectedItem) => ({ menuID, selectedItem })
);

export const fetchMenuFromCache = createAction(
  FETCH_MENU_FROM_CACHE,
  () => {},
  (menuID, selectedItem) => ({ menuID, selectedItem })
);

function getPath(id, data, path) {
  if (!data) {
    return null;
  }
  const matchObject = data.find((d) => d.id === id);
  if (matchObject) {
    path.push(matchObject);
    return path;
  }
  return data.reduce((prev, d) => getPath(id, d.children, [...path, d]) || prev, null);
}

export const resetMenu = createAction(RESET_MENU);

export function fetchMenu(menuID, selectedItem) {
  return (dispatch, getState) => {
    dispatch(resetMenu());
    if (getState().menu.menuCache[menuID]) {
      dispatch(fetchMenuFromCache(menuID, selectedItem));
    } else {
      dispatch(doFetchMenu(menuID, selectedItem));
    }
  };
}

export const doAppendLevel = createAction(
  APPEND_LEVEL,
  () => {},
  (level, menuItem) => ({ level, menuItem })
);

function appendLevel(menuData, level, item) {
  if (menuData.indexOf(item) !== -1) {
    return menuData[menuData.length - 1].id;
  }
  if (menuData.length > level) {
    menuData.splice(level);
  }
  menuData.push(item);
  if (item.children && item.children.length) {
    return appendLevel(menuData, level + 1, item.children[0]);
  }
  return item.id;
}

function parseMenu(menuData) {
  if (menuData && menuData.length === 1 && menuData[0].children && menuData[0].children.length) {
    return appendLevel(menuData, 1, menuData[0].children[0]);
  }
  return menuData[menuData.length - 1].id;
}

const emptyMenu = { searchId: null, menuItems: [], menuCache: {} };
// const Ids = []
// const UniqueIds = (Items) => {
//   Items.forEach(item => {
//     if (Ids.includes(item.id)) { window.alert("this id is duplicate:" , item.id) }
//     Ids.push(item.id)
//     if (item.children) {
//       UniqueIds(item.children)

//     }
//   });
// }
export const menu = handleActions(
  {
    [FETCH_MENU]: (state, { payload, meta }) => {
      const { menuCache } = state;
      let menuItems =
        payload && payload.data
          ? payload.data.map((menuData) => ({
              children: JSON.parse(menuData.f001t_Menue_json).data,
            }))
          : [];

      if (menuItems[0] && meta.selectedItem) {
        const path = getPath(meta.selectedItem, menuItems[0].children, []) || [];
        menuItems = [{ children: menuItems[0].children }, ...path];
      }
      // UniqueIds(menuItems[0].children)
      if (!menuItems.length) {
        return { ...emptyMenu };
      }
      menuCache[meta.menuID] = menuItems;
      return { menuItems, menuCache, searchId: parseMenu(menuItems), menuId: meta.menuID };
    },
    [FETCH_MENU_FROM_CACHE]: (state, { meta }) => {
      let menuItems = state.menuCache[meta.menuID];
      if (menuItems[0] && meta.selectedItem) {
        const path = getPath(meta.selectedItem, menuItems[0].children, []) || [];

        menuItems = [{ children: menuItems[0].children }, ...path];
      }
      const searchId = parseMenu(menuItems);
      return { ...state, searchId, menuItems, menuId: meta.menuID };
    },
    [APPEND_LEVEL]: (state, { meta }) => {
      const menuItems = [...state.menuItems];
      const searchId = appendLevel(menuItems, meta.level, meta.menuItem);
      return { searchId, menuItems };
    },
    [RESET_MENU]: () => ({ ...emptyMenu }), // state, action
  },
  emptyMenu
);
