import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import stringFormat from 'string-format';
import styled from 'styled-components';
import querystring from 'query-string';
import { Helmet } from 'react-helmet';
import TreeMenu from '../components/TreeMenu';
import SearchObjectList from '../components/SearchObjectList';
import trans, { getVideoParamsMenu, translatedTextForObject } from '../components/trans';
import {
  // changeActual,
  unsetBackFromDetail,
  setQuerystring,
  doSearchFromIdFromCache,
  resetSearch,
} from '../actions/search';
import { doCacheSearch } from '../actions/searchCache';
import { fetchMenu, doAppendLevel } from '../actions/menu';
import { setRoute } from '../actions/config';
import doMultiDbQueries from '../actions/doMultiDbQueries';
import { build_selected_everything_for_query } from './locationparameterparserUtils';
import { useLocation, useNavigate, useParams } from 'react-router';

const Container = styled.div`
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;
`;

const SearchResultContainer = styled.div`
  background-color: ${(props) => props.theme.searchResultsBg};
  @media (min-width: 993px) {
    margin-top: ${(props) => props.$marginTop}!important;
  }
  padding-left: 0px;
  padding-right: 0px;
`;

function getLastElementId(element) {
  if (element.children) {
    return getLastElementId(element.children[0]);
  }
  return element.id;
}

function searchFromId({
  query,
  services,
  searchCache,
  dispatch,
  selected_doctypes,
  selected_authors,
  selected_states,
}) {
  // req_nummber

  const cacheVal = searchCache[query];
  if (cacheVal) {
    dispatch(setQuerystring(query));
    dispatch(
      doSearchFromIdFromCache({
        parsedResponse: cacheVal,
        query,
        selected_doctypes,
        selected_authors,
        selected_states,
      })
    );
    // dispatch(resetSearch(null));
    return;
  }

  dispatch(resetSearch());
  dispatch(setQuerystring(query));

  doMultiDbQueries({
    databases: services.map((service) => ({
      url: stringFormat(service.ObjectsUrl, service.db),
      name: service.db,
    })),
    RFMscriptParam: query,
    RFMscript: 'RESTMegaMenuListFindSort',
  }).then(({ parsedResponse }) => {
    dispatch(doCacheSearch({ id: query, value: parsedResponse }));
    dispatch(
      doSearchFromIdFromCache({
        parsedResponse,
        query,
        selected_doctypes,
        selected_authors,
        selected_states,
      })
    );
  });
}


function findNameByURL(menuItems, url) {
  const match = menuItems.find((item) => item.url === url)
  if (!match) return null
  // console.log(match.name)
  return translatedTextForObject(match.name)
}


// function findNameByURL(menuItems, url) {
//   const match = menuItems.find((item) => item.url === url)
//   if (!match) return null
//   console.log(match.name)
//   return translatedTextForObject(match.name)
// }

export default function PagesViewer() {
  const { pageName, menuitem } = useParams()

  const navigate = useNavigate()
  const location = useLocation()

  const dispatch = useDispatch();
  const search = useSelector((state) => state.search);
  const searchCache = useSelector((state) => state.searchCache);
  const config = useSelector((state) => state.config);

  const { menu: navBarItems } = config;
  const { searchId, menuItems } = useSelector((state) => state.menu);

  const pageTitle = useMemo(() => {
    const name = findNameByURL(navBarItems, pageName);
    if (name) {
      return name
    }
    return location.pathname
  }, [navBarItems, pageName, location.pathname])

  const [newItemSelected, setNewSelectedItem] = useState(false);

  const { selected_doctypes, selected_authors, selected_states } = useMemo(
    () => querystring.parse(location.search),
    [location.search]
  );

  const menuTab = useMemo(() => {
    return navBarItems.find((item) => item.url === pageName);
  }, [navBarItems, pageName]);

  // console.log('pageName', pageName, 'menuitem', menuitem, navBarItems)

  const research = useCallback(
    (query) => {
      searchFromId({
        query: query || searchId,
        services: menuTab.services,
        searchCache,
        dispatch,
        ...build_selected_everything_for_query(
          selected_doctypes,
          selected_authors,
          selected_states
        ),
      });
    },
    [searchId, menuTab, searchCache, dispatch, selected_doctypes, selected_authors, selected_states]
  );

  const updatePage = useCallback(
    (cond) => {
      if (menuTab && menuTab.menuUrl) {
        if (!cond) {
          const selectedItem = menuitem;
          dispatch(fetchMenu(menuTab.menuUrl, selectedItem));
        }
        if (searchId) {
          research();
        }
      }
    },
    [menuTab, searchId, menuitem, dispatch, research]
  );

  useEffect(() => {
    if (search.backFromDetailView || search.filteredResults) {
      updatePage();
    } else {
      const pageUrl = pageName;
      if (location.pathname.indexOf(`/${pageUrl}/`) !== -1) {
        const selectedItem = menuitem;
        if (menuTab) {
          dispatch(fetchMenu(menuTab.menuUrl, selectedItem));
        }
      } else {
        dispatch(setRoute({ route: null }));
      }
      dispatch(unsetBackFromDetail());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    location.pathname,
    menuTab,
    menuitem,
    pageName,
    search.backFromDetailView,
    // search.filteredResults,
    updatePage,
  ]);

  useEffect(() => {
    // why we need this?
    // we use the some data store for the search page and this component
    // we should separate them in the feature => unnneded dependency and complication!
    const isCompanyProducts = search.allResults.type === 'COMPANY_PRODUCTS';
    if (isCompanyProducts) {
      updatePage(isCompanyProducts);
    }
    if (search.key !== searchId) {
      research();
    }
  }, [search, searchId, navBarItems, updatePage, pageName, menuitem, research]);

  useEffect(() => {
    if (menuTab) {
      dispatch(fetchMenu(menuTab.menuUrl, menuitem));
    }
  }, [menuitem, menuTab, dispatch]);

  const searchListMarginTop = useMemo(() => {
    const marginTopWithoutOffset = (menuTab && menuTab.rearchResultsMarginTop) || 330;
    const selectedItem = menuItems[menuItems.length - 1];
    if (!selectedItem) {
      return marginTopWithoutOffset;
    }
    const hasVideo = getVideoParamsMenu(selectedItem);
    const offset = hasVideo ? 330 : 0;
    return marginTopWithoutOffset + offset;
  }, [menuItems, menuTab]);

  const selectedTreeItemFn = useCallback(
    (level, menuItemNext) => {
      const nextId = getLastElementId(menuItemNext);
      navigate(`/${pageName}/${nextId}/`);
      dispatch(doAppendLevel(level, menuItemNext));
    },
    [dispatch, navigate, pageName]
  );

  // if (!menuTab) {
  //   return <div>404 nur falls requests schon nicht erfolgreich sind</div>
  // }

  return (
    <Container
      className={`clearfix ${menuTab ? menuTab.menuClassName : ''}`}
      style={{ position: 'relative' }}
    >
      {searchId !== null ? (
        <TreeMenu
          isMenuTabTools={menuTab && menuTab.url === 'tools' ? true : false}
          columnsClassName={(menuTab ? menuTab.TreeMenuColumnsClass : undefined) || undefined}
          TreeMenuImageClass={(menuTab ? menuTab.TreeMenuImageClass : undefined) || undefined}
          columns={menuItems}
          selected={selectedTreeItemFn}
          displayDocumentCount={menuTab && menuTab.displayDocumentCount}
        />
      ) : (
        <p className="text-center">{trans('loader.loading')}</p>
      )}
      <SearchResultContainer $marginTop={`${searchListMarginTop}px`}>
        {menuItems.length ? (
          <SearchObjectList
            newItemSelected={newItemSelected}
            changeItemSelected={setNewSelectedItem}
          />
        ) : null}
      </SearchResultContainer>
      <Helmet title={pageTitle} />
    </Container>
  );
}
